import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import JobForm from './job_form'
import OffersForm from './offers_form'
import SchoolForm from './school_form'
import SchoolProgramForm from './school_program_form'
import Nav from '../nav'
import Footer from '../footer'

const Admin = props => {
  return (
    <>
      <Nav />
      <div className="admin__container">
        <h1 className="u-margin-bottom-medium">Admin Panel</h1>

        <Tabs defaultActiveKey="schools" unmountOnExit={true}>

          <Tab eventKey="schools" title="Schools">
            <SchoolForm />
          </Tab>

          <Tab eventKey="school_programs" title="School Programs">
            <SchoolProgramForm />
          </Tab>

          <Tab eventKey="jobs" title="Jobs">
            <JobForm />
          </Tab>

          <Tab eventKey="offers" title="Offers">
            <OffersForm />
          </Tab>

        </Tabs>
      </div>

      <Footer />
    </>
  )
}

export default Admin
