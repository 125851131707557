import React from 'react'
import { Alert, Fade } from 'react-bootstrap'

const Alerts = (props) => {
  const { mapAlert } = props

  return (
    <Fade>
      <Alert
        className={'daasly-map-alerts'}
        variant={mapAlert.type}
        show={mapAlert.show}
        style={{ opacity: '0.8' }}
      >
        {mapAlert.text}
      </Alert>
    </Fade>
  )
}

export default Alerts
