import React from 'react'
import {Link} from 'react-router-dom'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import logo from 'images/places-2-play-logo.png'
import {useForm} from 'react-hook-form'
import fbIcon from '../images/miscellaneous_icons/fb-icon.png'
import fbKidsIcon from '../images/miscellaneous_icons/fb-icon-kids.png'
import instagramIcon from '../images/miscellaneous_icons/instagram-icon.png'
import twitterIcon from '../images/miscellaneous_icons/twitter-icon.png'
import phoneIcon from '../images/miscellaneous_icons/phone-icon.png'
import locationIcon from '../images/miscellaneous_icons/location-icon-white.png'

const Footer = props => {
  const { register, handleSubmit } = useForm()

  const subscribe = (data, e) => {
    e.preventDefault()
    // handle data
  }

  const renderSocialMediaIcons = () => {
    return (
      <div>
        <span className="footer__social--text">Follow Us</span>
        <a href="https://www.facebook.com/places2playcom-123013841103011" target="_blank">
          <img src={fbIcon} className="social-media-icon" />
        </a>

        <a href="https://www.facebook.com/Places2Play-kids-107256504468300" target="_blank">
          <img src={fbKidsIcon} className="social-media-icon" />
        </a>

        <a href="https://www.instagram.com/places2play_com/" target="_blank">
          <img src={instagramIcon} className="social-media-icon" />
        </a>

        <a href="https://twitter.com/places2play" target="_blank">
          <img src={twitterIcon} className="social-media-icon" />
        </a>
      </div>
    )
  }

  const renderAppLink = () => {
    return (
      <img
        src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/app-store-link-white.png`}
        className="app-download__icon"
      />
    )
  }

  return (
    <>
      <div className="footer__container--top">
        <div className="footer__text--container">
          <Link to="/"><img src={logo} className="logo u-margin-top-small"/></Link>

          <p className="footer__text u-margin-top-small">
            Places2Play.com is known for helping local intramural players and children connect with leagues and locations in their area. Places2Play.com is a South Florida company based in Miami Beach.
          </p>

          <div className="footer__icon--container u-margin-top-small">
            <img src={phoneIcon} className="footer__icon" />
            <span className="footer__icon--text">305.916.0394</span>
          </div>

          <div className="footer__icon--container">
            <img src={locationIcon} className="footer__icon u-margin-top-xs" />
            <span className="footer__icon--text">Miami Beach, FL 33141</span>
          </div>

        </div>

        <div className="footer__items">
          <Link to="/about"><span className="footer__item">About Us</span></Link>

          <a href="mailto:contact@places2play.com?subject=New Message From Website" target="blank">
            <span className="footer__item">Contact Us</span>
          </a>

          <Link to="/sports_venue_signup"><span className="footer__item">Add a Location</span></Link>
          <Link to="/terms"><span className="footer__item">Terms</span></Link>
          <Link to="/privacy"><span className="footer__item">Privacy</span></Link>
          <Link to="/sitemap"><span className="footer__item">Sitemap</span></Link>
          <Link to="/careers"><span className="footer__item">Careers</span></Link>
        </div>

        <div className="footer__subscription">
          <span className="footer__subscription--text">Subscribe to our</span>
          <br />
          <span className="footer__subscription--text-large">Newsletter</span>

          <form id="email-subscription" onSubmit={handleSubmit(subscribe)}>
            <input
              type="text"
              name="name"
              className="footer__subscription--input"
              placeholder="Name"
              ref={register}
            />
            <input
              type="text"
              name="email"
              className="footer__subscription--input"
              placeholder="E-Mail"
              ref={register}
            />

            <button className="footer__subscription--btn">Subscribe</button>
          </form>
        </div>

      </div>

      <div className="footer__container--bottom">
        <span className="footer__bottom--text">Copyright 2020 Places2Play</span>
        {renderAppLink()}
        {renderSocialMediaIcons()}
      </div>
    </>
  )
}

export default Footer
