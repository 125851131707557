import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {offersRoute, updateOfferRatingRoute} from '../utils/routes'
import {storage} from '../firebase'
import {Carousel} from 'react-bootstrap'
import Nav from '../nav'
import { Link } from 'react-router-dom'
import StarRating from '../star_rating'
import {useAuthContext} from '../auth'
import Select from 'react-select'
import locationIcon from '../images/miscellaneous_icons/search-icon.png'
import Footer from '../footer'

const Offers = props => {
  const [offers, setOffers] = useState([])
  const [currentOffer, setCurrentOffer] = useState(null)
  const [imageURLs, setImageURLs] = useState({})
  const [locationSearch, setLocationSearch] = useState('')
  const {currentUser} = useAuthContext()

  useEffect(() => {
    const getOffers = () => {
      axios.get(offersRoute).then(r => setOffers(r.data))
    }
    getOffers()
  }, [])

  useEffect(() => {
    getImagesFromFirebase()
  }, [offers])

  const setRating = (stars, offer) => {
    const params = {user_email: currentUser.email, stars, id: offer.id}
    axios.post(updateOfferRatingRoute, params).then(r => console.log('r:', r))
  }

  const getImagesFromFirebase = () => {
    if (!offers.length) return null

    return offers.map(offer => {
      var listRef = storage.ref().child(`places2play_public/offers/${offer.image_folder}/`)

      var offerURLs = []
      listRef.listAll().then(res => {
        res.items.forEach(imageRef => {
          imageRef.getDownloadURL().then(url => {
            offerURLs.push(url)
            setImageURLs(prevState => ({...prevState, [offer.id]: offerURLs}))
          })
        })
      }).catch(error => console.log('Error: ', error))
    })
  }

  const renderOffers = () => {
    if (!offers.length) return <div className="offers__no-hits">No offers at this time.</div>

    return offers.map(offer => {
      return (
        <div className="offer__container" key={offer.id} onClick={() => setCurrentOffer(offer)}>
          {renderPercentageDiscount(offer)}

          <div key={offer.id} className="offer__content--container">
            <Carousel>
              {renderOfferImages(offer)}
            </Carousel>

            <h2 className="offer__header">{offer.name}</h2>

            <div className="offer__address">
              {offer.street_address}, {offer.city}, {offer.state}
            </div>

            <div className="offer__ratings--container">
              <span className="offer__ratings--average">{offer.average_rating}</span>
              <StarRating setRating={setRating} ratable={offer} />
              <span className="offer__ratings--count">{offer.review_count} Ratings</span>
            </div>

            <div className="offer__price">
              <span className="offer__price--before-discount">${offer.price_before_discount}</span>
              <span className="offer__price--after-discount">${offer.price_after_discount}</span>
            </div>
          </div>
        </div>
      )
    })
  }

  const renderPercentageDiscount = offer => {
    let percentage

    if (offer.price_after_discount < offer.price_before_discount) {
      percentage = Math.floor((offer.price_after_discount / offer.price_before_discount) * 100)
      return (
        <div className="offer__discount--container">
          <span className="offer__discount--percent">{percentage}% <br />Off</span>
        </div>
      )
    } else {
      return null
    }
  }

  const renderOfferImages = (offer, className='offer__image') => {
    if (!imageURLs[offer.id]) return null

    return imageURLs[offer.id].map(url => {
      return (
        <Carousel.Item key={url}>
          <img src={url} className={`${className}`} />
        </Carousel.Item>
      )
    })
  }

  const searchByLocation = () => {
    console.log('location: ', locationSearch)
    // TODO ask client what this function should do
  }

  const offerOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Street Address', value: 'street_address' },
    { label: 'City', value: 'city' },
    { label: 'State', value: 'state' },
    { label: 'Zip Code', value: 'zip' },
    { label: 'Price (lowest first)', value: 'price_after_discount' },
    { label: 'Price (highest first)', value: 'price_after_discount' }
  ]

  const handleOfferSort = (data) => {
    console.log('data: ', data)
    // TODO filter results by given data
  }

  const renderCurrentOffer = () => {
    if (!currentOffer) return null
    return (
      <>
        <div className="offers__current-offer--back-container">
          <b onClick={() => setCurrentOffer(null)} className="offers__current-offer--back">&larr; Back to all Offers</b>
        </div>

        <div className="offers__current-offer--container">
          <Carousel>
            {renderOfferImages(currentOffer, 'offers__current-offer--img')}
          </Carousel>

          <div className="offers__current-offer--text-container">
            <h1 className="offers__current-offer--header">{currentOffer.name}</h1>

            <div className="offers__current-offer--average-rating">
              <StarRating setRating={setRating} ratable={currentOffer} />
              ({currentOffer.review_count} Customer Reviews)
            </div>

            <h2 className="offers__current-offer--secondary-header">
              <span className="offers__current-offer--price-before-discount">
                ${currentOffer.price_before_discount}
              </span>

              <span className="offers__current-offer--price-after-discount">
                ${currentOffer.price_after_discount}
              </span>
            </h2>

            <div className="offers__current-offer--text">
              <span className="offers__current-offer--address">{currentOffer.street_address}, {currentOffer.city}, {currentOffer.state}</span>
              <p className="offers__current-offer--description">{currentOffer.description}</p>
            </div>

            <div className="offers__current-offer--btn-container">
              <input
                type="number"
                className="offers__current-offer--input"
                min="1"
              />
              <button className="offers__current-offer--btn">Add to Cart</button>
            </div>

          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="offers__container">
        <div className="offers__header--area">
          <Nav style="light" />
          <h1 className="header">Offers</h1>
        </div>

        <div className={`${currentOffer ? 'u-hide' : ''} offers__search--container`}>
          <div className="offers__search--input-container">
            <input
              type="text"
              className="select-location__input"
              placeholder="Search Your Location"
              onChange={e => setLocationSearch(e.target.value)}
              name="search_location"
            />

            <button className="btn__no-styles">
              <img src={locationIcon} className="select-location__icon" onClick={searchByLocation} />
            </button>
          </div>

          <Select
            options={offerOptions}
            name='sort-offers'
            placeholder='Sort By'
            onChange={handleOfferSort}
            className="offers__search--select"
          />
        </div>

        <div className={`${currentOffer ? 'u-hide' : ''} offers__list--container`}>
          {!currentOffer && renderOffers()}
        </div>

        {currentOffer && renderCurrentOffer()}
      </div>
      <Footer />
    </>
  )
}

export default Offers
