import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Nav from '../nav'
import Footer from '../footer'
import axios from 'axios'
import pickSportIcon from '../images/sports_icons/pick-a-sport.png'
import iconsArr from './icons'
import {otherSportsRoute} from '../utils/routes'

const SelectOtherSports = props => {
  const {league} = props.match.params
  const [sports, setSports] = useState([])

  useEffect(() => {
    const getOtherSports = () => {
      axios.get(otherSportsRoute).then(r => setSports(r.data))
    }
    getOtherSports()
  }, [])

  const renderSports = () => {
    if (!sports.length) return null

    sports.sort((a, b) => a.name.localeCompare(b.name))

    return sports.map(sport => {
      let iconSrc = iconsArr.find(iconPath => iconPath.includes(sport.icon))
      return (
        <div className="sports__item" key={sport.id}>
          <Link
            key={sport.id}
            to={`/${league}/${sport.name}/location`}
            className="sports__item--link"
          >
            <img
              src={iconSrc}
              className="sport__icon"
            />

            <span className="sport__name">{sport.name}</span>
          </Link>
        </div>
      )
    })
  }

  return (
    <div className="select_sport__container">
      <div className={`${league}_sports__header--area`}>
        <Nav style="light"/>
        <h1 className="header">Other Sports</h1>
      </div>

      <div className="select_sport__header--container">
        <img src={pickSportIcon} className="select_sport__icon" />

        <h2 className="select_sport__header--secondary">
          Pick a sport
        </h2>
      </div>

      <div className="sports__container--other">
        { renderSports() }
      </div>

      <Footer />
    </div>
  )
}

export default SelectOtherSports
