import React from 'react'

const GolfForm = ({ setFormData, formData, postScore }) => {
  return (
    <form id="scoreForm">
      <div className="form-group">
        <label htmlFor="golfScoreTotal" className="daasly-sidebar-score-label">
          Total Score
        </label>
        <input
          type="number"
          className="form-control"
          id="golfScoreTotal"
          onChange={(e) => setFormData({ ...formData, total_score: e.target.value })}
          value={formData ? formData.total_score : ''}
        />
        <small id="golfScoreTotalId" className="form-text text-muted">
          Enter your total score for each round.
        </small>
        <label htmlFor="golfScorePin" className="daasly-sidebar-score-label">
          Pin Color
        </label>
        <select
          className="form-control"
          id="golfScorePin"
          value={formData ? formData.pin_color : 'select an option'}
          onChange={(e) => setFormData({ ...formData, pin_color: e.target.value })}
        >
          <option value="select an option" disabled>
            select an option
          </option>
          <option value="red">Red</option>
          <option value="white">White</option>
          <option value="blue">Blue</option>
          <option value="green">Green</option>
          <option value="gold">Gold</option>
          <option value="black">Black</option>
          <option value="silver">Silver</option>
        </select>
        <small id="golfScorePinId" className="form-text text-muted">
          Select the color pins you played from.
        </small>
        <button
          type="button"
          className="btn btn-primary float-right daasly-sidebar-score-button"
          onClick={postScore}
        >
          Submit Score
        </button>
      </div>
    </form>
  )
}

export default GolfForm
