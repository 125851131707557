import React, {useContext, useState} from 'react'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap'
import {suggestEditRoute} from '../utils/routes'
import Select from 'react-select'
import {AuthContext} from '../auth'
import {leagueOptions, axiosHelper} from '../utils'

const SuggestEdit = ({venue}) => {
  const [showModal, toggleModal] = useState(true)
  const [selectedLeague, setLeague] = useState(venue.league)
  const {register, handleSubmit} = useForm()
  const {currentUser} = useContext(AuthContext)

  const handleClose = () => {
    toggleModal(false)
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    const params = {content: data.content, sports_venue_id: venue.id, user_email: currentUser.email}
    axiosHelper()
    axios
      .post(suggestEditRoute, params)
      .then(r => alert("Thanks for your suggestion!"))
    toggleModal(false)
  }

  return (
    <Modal show={showModal} onHide={handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Suggest an Edit for {venue.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="suggest-edit__form">
          <input
            type="text"
            className="suggest-edit__input"
            placeholder={`Is something inaccurate for ${venue.name}?`}
            name="content"
            ref={register}
          />

          <button onClick={handleSubmit} className="btn__medium u-margin-top-small">
            Save and Close
          </button>
        </form>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuggestEdit
