import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Favorite from './Favorite'
import Played from './Played'
import Share from './Share'

const Body = props => {
  const { selectedVenue, currentUserId } = props

  const renderName = name => {
    const displayName =
      name.length > 18 ? (
        <OverlayTrigger
          placement={'top'}
          overlay={<Tooltip>{selectedVenue.name}</Tooltip>}
        >
          <div>{`${name.substring(0, 18)}...`}</div>
        </OverlayTrigger>
      ) : (
        <div>{name}</div>
      )
    return displayName
  }

  const renderWebsite = website => {
    return (
      <a href={website} className='daasly-popup-link' target="blank">
        {website
          ? website
              .replace('http://', '')
              .replace('https://', '')
              .replace('www.', '')
          : 'None'}
      </a>
    )
  }

  return (
    <>
      <div className='map-popup__header'>
        {renderName(selectedVenue.name)}

        <div>
          {/* <Played
            currentUserId={currentUserId}
            selectedVenue={selectedVenue}
            toggleInteraction={props.toggleInteraction}
            postAlert={props.postAlert}
          /> */}
          {/* <Favorite
            currentUserId={currentUserId}
            selectedVenue={selectedVenue}
            toggleInteraction={props.toggleInteraction}
            postAlert={props.postAlert}
          /> */}
          <Share selectedVenue={selectedVenue} postAlert={props.postAlert} />
        </div>
      </div>

      <div className='map-popup__body'>
        <div><b>Address: </b>{selectedVenue.street_address}</div>
        <div><b>Phone: </b>{selectedVenue.phone || 'None'}</div>
        <div><b>Website: </b>{renderWebsite(selectedVenue.website)}</div>
      </div>

      <div className='daasly-popup-footer'></div>
    </>
  )
}

export default Body
