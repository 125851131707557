import Softball from '../images/sports_icons/001-softball.png'
import Baseball from '../images/sports_icons/baseball-bat.png'
import Golf from '../images/sports_icons/golf.png'
import Basketball from '../images/sports_icons/basketball.png'
import Football from '../images/sports_icons/football.png'
import Tennis from '../images/sports_icons/tennis.png'
import Soccer from '../images/sports_icons/02-soccer.png'
import Hockey from '../images/sports_icons/027-hockey-stick.png'
import Swimming from '../images/sports_icons/swimming.png'
import Volleyball from '../images/sports_icons/006-volleyball.png'
import Yoga from '../images/sports_icons/010-yoga.png'
import Gymnastics from '../images/sports_icons/gymnastics.png'
import Diving from '../images/sports_icons/watersports.png'
import Sailing from '../images/sports_icons/021-sailing.png'
import Skiing from '../images/sports_icons/ski.png'
import Running from '../images/sports_icons/028-running.png'
import Boxing from '../images/sports_icons/003-boxing-gloves.png'
import Gym from '../images/sports_icons/gym@2x.png'
import MartialArts from '../images/sports_icons/011-karate.png'
import Polo from '../images/sports_icons/polo.png'
import Rowing from '../images/sports_icons/rowing.png'
import Rugby from '../images/sports_icons/rugby.png'
import Camping from '../images/sports_icons/camping.png'
import Bowling from '../images/sports_icons/004-bowling-pins.png'
import WaterSports from '../images/sports_icons/watersports.png'
import TableTennis from '../images/sports_icons/tennis.png'
import CrossCountry from '../images/sports_icons/028-running.png'
import Walking from '../images/sports_icons/other.png'
import Track from '../images/sports_icons/028-running.png'
import Poker from '../images/sports_icons/026-poker-cards.png'
import Dodgeball from '../images/sports_icons/dodgeball.png'
import Cycling from '../images/sports_icons/016-cycling.png'
import Climbing from '../images/sports_icons/008-climbing.png'
import Karate from '../images/sports_icons/011-karate.png'
import Paintball from '../images/sports_icons/paintball.png'
import Fishing from '../images/sports_icons/012-fishing.png'
import Other from '../images/sports_icons/other.png'
import Music from '../images/sports_icons/music.png'
import Playground from '../images/sports_icons/playground.png'
import Dance from '../images/sports_icons/dance.png'
import RollerSkating from '../images/sports_icons/roller-skating.png'
import Kickball from '../images/sports_icons/kickball.png'
import Skateboarding from '../images/sports_icons/skateboarding.png'
import Pickleball from '../images/sports_icons/pickleball.png'
import FigureSkating from '../images/sports_icons/figure-skating.png'
import Pool from '../images/sports_icons/pool.png'

export default [
  Softball,
  Baseball,
  Golf,
  Basketball,
  Football,
  Tennis,
  Soccer,
  Hockey,
  Swimming,
  Volleyball,
  Yoga,
  Gymnastics,
  Diving,
  Sailing,
  Skiing,
  Running,
  Boxing,
  Gym,
  MartialArts,
  Polo,
  Rowing,
  Rugby,
  Camping,
  Bowling,
  WaterSports,
  TableTennis,
  CrossCountry,
  Walking,
  Track,
  Poker,
  Dodgeball,
  Cycling,
  Climbing,
  Karate,
  Paintball,
  Fishing,
  Other,
  Music,
  Playground,
  Dance,
  RollerSkating,
  Kickball,
  Skateboarding,
  Pickleball,
  FigureSkating,
  Pool
]
