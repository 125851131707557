import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import ReactMapGL from 'react-map-gl'
import { mapKeyRoute } from '../utils/routes'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import MapMarker from './components/MapMarker'
import MapControls from './components/MapControls'
import Alerts from './components/Alerts'

const Map = props => {
  const { venues, selectedVenue } = props
  const [mapAlert, setMapAlert] = useState({
    show: false,
    type: '',
    text: ''
  })
  const [mapKey, setMapKey] = useState()
  const [viewport, setViewport] = useState({
    latitude: 25.802099,
    longitude: -80.203403,
    zoom: 11
  })
  const history = useHistory()

  useEffect(() => {
    getMapKey()
    if (venues.length) setDefaultViewport()
    getUserDefaultLocation()
  }, [])

  useEffect(() => {
    setMapCenter(venues[0])
  }, [venues])

  useEffect(() => {
    setMapCenter(selectedVenue)
  }, [selectedVenue])

  const setDefaultViewport = venue => setViewport({latitude: venue.latitude, longitude: venue.longitude})

  const getUserDefaultLocation = () => {
    history.location.pathname === '/places_played'
      ? navigator.geolocation.getCurrentPosition(location => {
          if (location) {
            setViewport({
              ...viewport,
              latitude: location.coords.latitude,
              longitude: location.coords.longitude,
              zoom: 12
            })
          }
        })
      : null
  }

  const postAlert = (show, type, text) => {
    const params = { show, type, text }
    setMapAlert({ ...params })
    setTimeout(() => {
      setMapAlert({ show: false })
    }, 1500)
  }

  const setMapCenter = venue => {
    if (venue && venue.latitude && venue.longitude) {
      const latitude = venue.latitude
      const longitude = venue.longitude
      setViewport({
        ...viewport,
        latitude,
        longitude
      })
    }
  }

  const getMapKey = () => {
    axios.get(mapKeyRoute).then(r => {
      setMapKey(r.data.map_key)
    })
  }

  const getRender = venues => {
    const renderMap = (
      <div className="map__container">
        <Link to="/sports_venue_signup" className="signup-text">
          Add your business
        </Link>

        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken={mapKey}
          mapStyle='mapbox://styles/mapbox/streets-v11'
          onViewportChange={viewport => setViewport(viewport)}
          width='100%'
          height='80vh'
        >
          <Alerts mapAlert={mapAlert} />
          <MapMarker
            {...props}
            postAlert={postAlert}
            setViewport={setViewport}
          />
          <MapControls viewport={viewport} />
        </ReactMapGL>
      </div>
    )
    return renderMap
  }

  return (
    <div className='daasly-map-outer'>
      {venues && mapKey ? (
        getRender(venues)
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner animation='grow' />
        </div>
      )}
    </div>
  )
}

export default Map
