import React from 'react'
import {Link} from 'react-router-dom'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'

const EnterToWin = props => {
  return (
    <div className="enter-to-win__container">
      <div className="enter-to-win__text--container">
        <img
          className="enter-to-win__img"
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/enter-to-win.png?ignoreCache=1`}
        />

        <h2>
          2021 Prize<br />
          Titleist SM8 Wedge Set
        </h2>

        <div className="enter-to-win__text">
          Team Places2Play.com prides itself on having the most updated, comprehensive, and accurate information in the business. We have a team of people diligently updating locations, details, and valuable information for you. We also rely on our users (you!) to submit info that keeps everyone up to date. As a THANK YOU, we'll reward users via monthly contests – awesome sporting equipment, apparel, and more. Simply help us enter new locations and earn more entries to win. See these <a>terms & conditions</a> for more info – and good luck!
        </div>
      </div>

      <div className="enter-to-win__right-col">
        <img
          className="enter-to-win__prize"
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/enter-to-win-prize.png?ignoreCache=1`}
        />
        <Link to="/sports_venue_signup"><div className="enter-to-win__btn">Add a Location</div></Link>
      </div>

    </div>
  )
}

export default EnterToWin
