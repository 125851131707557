import React, {useState} from 'react'
import {FaStar} from 'react-icons/fa'

const StarRatingReview = ({value}) => {

  return (
    <div>
      {[...Array(5)].map((star, i) => {
        const starRatingValue = i + 1

        return (
          <label key={starRatingValue}>
            <input
              type="radio"
              name="rating"
              className="star-rating"
              value={value}
            />
            <FaStar
              size={18}
              className="star"
              color={starRatingValue <= value ? "#ffc107" : ""}
            />
          </label>
        )
      })}
    </div>
  )
}

export default StarRatingReview
