import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import validator from 'validator'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import axios from 'axios'
import { currentUserRoute, userImageRoute, userUpdateProfile, sportsRoute } from '../utils/routes'
import { AuthContext } from '../auth'
import {useDropzone} from 'react-dropzone'
import {storage} from '../firebase'
import Nav from '../nav'
import Footer from '../footer'

const Profile = props => {
  const {currentUser} = useContext(AuthContext)
  const [imageFiles, setImageFiles] = useState([])
  const [sportOptions, setSportOptions] = useState([])
  const [emailError, setEmailError] = useState('')
  const [userImage, setUserImage] = useState(null)
  const [showImageForm, toggleImageForm] = useState(false)
  const [userData, setUserData] = useState(null)
  const [userName, setUserName] = useState()
  const [userEmail, setUserEmail] = useState()
  const [userDob, setUserDob] = useState()
  const [userLocation, setUserLocation] = useState()
  const [userFavoriteSport, setUserFavoriteSport] = useState()
  const [showUserNameForm, toggleUserNameForm] = useState(false)
  const [showUserEmailForm, toggleUserEmailForm] = useState(false)
  const [showUserDobForm, toggleUserDobForm] = useState(false)
  const [showUserLocationForm, toggleUserLocationForm] = useState(false)
  const [showUserFavoriteSportForm, toggleUserFavoriteSportForm] = useState(false)
  const [progress, setProgress] = useState(0)

  const createSportOptions = sportObjs => {
    // Format response objects into value/label objs for react-select
    setSportOptions(sportObjs.map(obj => {
      return {value: obj.id, label: obj.name}
    }))
  }

  useEffect(() => {
    const getSports = () => {
      axios.get(sportsRoute).then(r => createSportOptions(r.data))
    }
    getSports()
  }, [])

  useEffect(() => {
    const getUserImage = () => {
      if (currentUser) {
        const params = {firebase_uid: currentUser.uid}
        axios.get(userImageRoute, {params})
          .then(r => r.data.success && setUserImage(r.data.image_url))
      }
    }

    const getUserData = () => {
      if (currentUser) {
        const params = {firebase_uid: currentUser.uid}
        axios.get(currentUserRoute, {params})
          .then(r => setUserData(r.data))
      }
    }

    getUserImage()
    getUserData()
  }, [currentUser])

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImageFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const uploadImageToFirebase = (imageFiles) => {
    imageFiles.forEach(imageFile => {
      const refPath = `places2play_public/users/${currentUser.uid}/`
      const fileName = imageFile.name.replace(/ +/g, "-")
      const uploadTask = storage.ref(refPath).child(fileName).put(imageFile)

      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {console.log('Error: ', error)},
        () => {
          storage
            .ref(refPath)
            .child(fileName)
            .getDownloadURL()
            .then(image_url => {
              const params = {image_url, firebase_uid: currentUser.uid}
              axios.post(userImageRoute, params)
                .then(r => r.data.success && setUserImage(r.data.user.image_url) && window.location.reload(true))
            })
        }
      )
    })
  }

  const thumbs = imageFiles.map(file => (
    <div key={file.name} className="dropzone-thumb">
      <img src={file.preview} className="dropzone-preview-img" />
      <span className="u-margin-left-small">{file.name} - {file.size} bytes</span>
    </div>
  ))

  const renderImgOrUpload = () => {
    if (userImage) { // user's uploaded image
      return (
        <div className="user-profile__avatar--container">
          <img src={userImage} className="user-profile__avatar" />

          <span onClick={() => toggleImageForm(!showImageForm)} className="user-profile__img--link">
            Upload a new image
          </span>

          {showImageForm && renderImageForm()}
        </div>
      )
    } else if (currentUser.photoURL) { // default img from Firebase
      return (
        <div className="user-profile__avatar--container">
          <img src={currentUser.photoURL} className="user-profile__avatar" />

          <span onClick={() => toggleImageForm(!showImageForm)} className="user-profile__avatar--link">
            Upload a new image
          </span>

          {showImageForm && renderImageForm()}
        </div>
      )
    } else {
      return (
        <div>
          You don't have a profile picture yet.
          {renderImageForm()}
        </div>
      )
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    uploadImageToFirebase(imageFiles)
  }

  const renderImageForm = () => {
    return (
      <>
        <div className="u-margin-top-medium">
          <h4>Upload a new Profile Picture</h4>
          {progress === 100 ? <h3 className="upload-success">Your picture has been uploaded!</h3> : null}
          {progress === 100 ? <span className="upload-success--small">Please refresh the page</span> : null}
          {progress > 0 && <progress value={progress} max="100" className="images-progress-bar" />}
          <div className="modal__body picture-dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n' Drop an image here, or click to select a file.</p>
          </div>

          <button className="u-margin-top-small btn__large" onClick={onSubmit}>
            Add your Picture
          </button>
        </div>

        <aside className="u-margin-top-small">
          <ul>{thumbs}</ul>
        </aside>
      </>
    )
  }

  const renderUserNameForm = () => {
    return (
      <>
        <input
          type="text"
          className="user-profile__item--content"
          placeholder={userData.name}
          onChange={e => setUserName(e.target.value)}
        />
        <span className="user-profile__save" onClick={() => saveUserDetails({name: userName})}>Save</span>
      </>
    )
  }

  const renderUserEmailForm = () => {
    return (
      <>
        <input
          type="email"
          className="user-profile__item--content"
          placeholder={userData.email}
          onChange={e => validateEmail(e.target.value)}
        />
        <span className="user-profile__email-error">{emailError}</span>
        <span className="user-profile__save" onClick={() => saveUserDetails({email: userEmail})}>Save</span>
      </>
    )
  }

  const validateEmail = email => {
    if (validator.isEmail(email)) {
      setEmailError('Valid Email :)')
      setUserEmail(email)
    } else {
      setEmailError('Please enter a valid email.')
    }
  }

  const renderUserDobForm = () => {
    return (
      <>
        <input
          type="date"
          className="user-profile__item--content"
          placeholder={userData.dob}
          onChange={e => setUserDob(e.target.value)}
        />
        <span className="user-profile__save" onClick={() => saveUserDetails({dob: userDob})}>Save</span>
      </>
    )
  }

  const renderUserLocationForm = () => {
    return (
      <>
        <input
          type="text"
          className="user-profile__item--content"
          placeholder={userData.location}
          onChange={e => setUserLocation(e.target.value)}
        />
        <span className="user-profile__save" onClick={() => saveUserDetails({user_location: userLocation})}>Save</span>
      </>
    )
  }

  const renderUserFavoriteSportForm = () => {
    return (
      <>
        <Select
          options={sportOptions}
          onChange={sport => setUserFavoriteSport(sport)}
        />
        <span className="user-profile__save" onClick={() => saveUserDetails({favorite_sport: userFavoriteSport})}>Save</span>
      </>
    )
  }

  const saveUserDetails = params => {
    //// TODO
    //if (!params.email === undefined || !validateEmail(params.email)) {
      //alert('Invalid email address.')
      //return
    //}

    params.firebase_uid = currentUser.uid
    axios.post(userUpdateProfile, params).then(r => {
      if (r.status === 200) {
        alert('Your changes have been saved!')
        setUserData(r.data)
        hideAllForms()
      } else {
        alert('Something went wrong. Please try again.')
      }
    })
  }

  const hideAllForms = () => {
    toggleUserNameForm(false)
    toggleUserEmailForm(false)
    toggleUserDobForm(false)
    toggleUserLocationForm(false)
    toggleUserFavoriteSportForm(false)
  }

  const renderUserDetails = () => {
    if (!userData) return null
    return (
      <ul className="user-profile__details--container">
        {renderImgOrUpload()}

        <div className="user-profile__data--container">
          <li className="user-profile__item">
            <span className="user-profile__item--header">
              Name
            </span>

            <div className="user-profile__item--container">
              <span className="user-profile__item--content">
                {showUserNameForm ? renderUserNameForm() : userData.name}
              </span>

              <span className="user-profile__edit" onClick={() => toggleUserNameForm(!showUserNameForm)}>
                Edit
              </span>
            </div>
          </li>

          <li className="user-profile__item">
            <span className="user-profile__item--header">
              E-Mail
            </span>

            <div className="user-profile__item--container">
              <span className="user-profile__item--content">
                {showUserEmailForm ? renderUserEmailForm() : userData.email}
              </span>

              <span className="user-profile__edit" onClick={() => toggleUserEmailForm(!showUserEmailForm)}>
                Edit
              </span>
            </div>
          </li>

          <li className="user-profile__item">
            <span className="user-profile__item--header">
              DOB
            </span>

            <div className="user-profile__item--container">
              <span className="user-profile__item--content">
                {showUserDobForm ? renderUserDobForm() : userData?.dob || 'Please enter your Date of Birth'}
              </span>

              <span className="user-profile__edit" onClick={() => toggleUserDobForm(!showUserDobForm)}>
                Edit
              </span>
            </div>
          </li>

          <li className="user-profile__item">
            <span className="user-profile__item--header">
              Location
            </span>

            <div className="user-profile__item--container">
              <span className="user-profile__item--content">
                {showUserLocationForm ? renderUserLocationForm() : userData && userData['location'] || 'Please enter your location'}
              </span>

              <span className="user-profile__edit" onClick={() => toggleUserLocationForm(!showUserLocationForm)}>
                Edit
              </span>
            </div>
          </li>

          <li className="user-profile__item">
            <span className="user-profile__item--header">
              Favorite Sport
            </span>

            <div className="user-profile__item--container">
              <span className="user-profile__select--container">
                {showUserFavoriteSportForm ? renderUserFavoriteSportForm() : userData && userData.favorite_sport_name || 'Please enter your favorite sport'}
              </span>

              <span className="user-profile__edit" onClick={() => toggleUserFavoriteSportForm(!showUserFavoriteSportForm)}>
                Edit
              </span>
            </div>
          </li>
        </div>

      </ul>
    )
  }

  const renderFriends = () => {
    if (!userData) return null
    return (
      <>
        <span className="user-profile__item--header">
          Friends
        </span>

        <div className="user-profile__friends--container">
          {renderFriendsAvatars()}
        </div>
      </>
    )
  }

  const renderFriendsAvatars = () => {
    if (!userData) return null
    return userData.friends.map(f => {
      const friendKey = f.id + Math.floor(Math.random(10000)*10000)
      return (
        <div key={friendKey} className="user-profile__friend--container">
          <img src={f.image_url} className="user-profile__friend--img" />
          <span className="user-profile__friend--name">{f.name || 'None'}</span>
        </div>
      )
    })
  }

  if (!currentUser) return (
    <>
      <Nav />

      <div className="user-profile__container">
        <div className="u-margin-left-medium u-margin-bottom-xl">Please sign in.</div>
      </div>

      <Footer />
    </>
  )

  return (
    <>
      <Nav />

      <div className='user-profile__container'>

        <div className="user-profile__text--container">
          {renderUserDetails()}
          {renderFriends()}
        </div>

        <div className="user-profile__img--container">
          <img
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/places2play-profile.png`}
            className="user-profile__img" />
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Profile
