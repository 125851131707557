import React, {useState, useEffect} from 'react'
import {storage} from '../firebase'
import {useForm} from 'react-hook-form'
import Spinner from '../spinner'
import axios from 'axios'
import {sportsVenueRoutes, imagesRoute} from '../utils/routes'
import Select from 'react-select'
import {Modal, Button} from 'react-bootstrap'
import {axiosHelper} from '../utils/index'
import {leagueOptions} from '../utils'

const SportsVenueForm = ({id, league}) => {
  const [show, toggleShow] = useState(true)
  const [selectedLeague, setLeague] = useState(league)
  const [showSpinner, toggleSpinner] = useState(false)
  const [sportsVenue, setSportsVenue] = useState({})
  const [imageSucessMsg, setImageSuccessMsg] = useState(false)
  const {register, handleSubmit} = useForm()

  useEffect(() => {
    const getSportsVenue = () => {
      axios
        .get(`/api/sports-venues/${id}`)
        .then(r => setSportsVenue(r.data.sports_venue))
    }
    getSportsVenue()
  }, [])

  const onSubmit = (data, e) => {
    e.preventDefault();
    Object.keys(data).forEach(key => (data[key] == "") && delete data[key])
    delete data.image
    const params = {...data, id }
    axiosHelper()
    axios
      .patch(`${sportsVenueRoutes}/${id}`, {...params})
      .then(r => setSportsVenue(r.data.sports_venue))
    toggleShow(false)
  }

  const handleClose = () => toggleShow(false)

  const renderSpinner = () => {
    if (showSpinner) {
      return (
        <div className='spinner__container--modal'>
          <Spinner />
        </div>
      )
    }
  }

  const renderGolfDateInput = () => {
    if (sportsVenue.sport_name !== 'Golf') return null

    return (
            <>
              <label htmlFor="user-report-last-punch">
                User Report Last Punch
              </label>

              <input
                type="date"
                className="sports-venue__form--input"
                name="user_report_last_punch"
                ref={register}
              />
            </>
    )
  }

  const deleteImg = img => {
    var shouldDelete = confirm("Are you sure you want to delete this image?")

    if (shouldDelete) {
      var imgRef = storage.refFromURL(img.url)

      imgRef.delete().then(() => {
        const params = {id: img.id}

        axios.delete(imagesRoute + `/${img.id}`, params)
          .then(r => {
            if (r.data.success) {
              alert('Image deleted successfully!')
              window.location.reload()
            }
          })
      }).catch(e => {
        if (e.code === "storage/object-not-found") {
          const params = {id: img.id}

          axios.delete(imagesRoute + `/${img.id}`, params)
            .then(r => {
              if (r.data.success) {
                alert('Image deleted successfully!')
                window.location.reload()
              }
            })
        }
      })
    }
  }

  const renderVenueImages = venue => {
    if (!sportsVenue.images) return null

    return sportsVenue.images.map(img => {
      const key = img.url + '__' + sportsVenue.id
      return (
        <div className="sports-venue__form--img--item" key={key}>
          <img src={img.url} className="sports-venue__form--img" />

          <button onClick={() => deleteImg(img)} className="btn__large u-margin-top-small">
            Delete
          </button>
        </div>
      )
    })
  }

  return (
    <Modal show={show} onHide={handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>{sportsVenue.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <section className="modal__body">
          <form onSubmit={handleSubmit(onSubmit)} className="sports-venue__form">
            <input
              type="text"
              className="sports-venue__form--input"
              placeholder={sportsVenue.name || 'Name'}
              name="name"
              ref={register}
            />
            <input
              type="text"
              className="sports-venue__form--input"
              placeholder={sportsVenue.street_address || 'Street Address'}
              name="street_address"
              ref={register}
            />
            <input
              type="text"
              className="sports-venue__form--input"
              placeholder={sportsVenue.city || 'City'}
              name="city"
              ref={register}
            />
            <input
              type="text"
              className="sports-venue__form--input"
              placeholder={sportsVenue.state || 'State'}
              name="state"
              ref={register}
            />
            <input
              type="text"
              className="sports-venue__form--input"
              placeholder={sportsVenue.zip_code || 'Zip Code'}
              name="zip_code"
              ref={register}
            />
            <input
              type="number"
              className="sports-venue__form--input"
              placeholder={sportsVenue.phone || 'Phone Number'}
              name="phone"
              ref={register}
            />
            <input
              type="email"
              className="sports-venue__form--input"
              placeholder={sportsVenue.email || 'Email'}
              name="email"
              ref={register}
            />
            <input
              type="website"
              className="sports-venue__form--input"
              defaultValue={sportsVenue.website}
              name="website"
              ref={register}
            />
            {renderGolfDateInput()}
            <Select
              options={leagueOptions}
              ref={register}
              name="league"
              placeholder={league || "League"}
              defaultValue={{label: 'League', value: league}}
              onChange={setLeague}
            />

            <button onClick={handleSubmit} className="btn__large--gr u-margin-top-large">
              Save and Close
            </button>
          </form>

        </section>

        <div className="sports-venue__form--img--container">
          {renderVenueImages()}
        </div>
      </Modal.Body>

    </Modal>
  )
}

export default SportsVenueForm
