import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {sportsVideos} from '../utils'
import InstagramEmbed from 'react-instagram-embed'
import ReactPlayer from 'react-player'

const VideoOfTheMonth = ({sport}) => {
  const [showModal, toggleModal] = useState(true)

  const handleClose = () => {
    toggleModal(false)
  }

  const getVideoLink = () => {
    return sportsVideos.filter(videoObj => videoObj.sport === sport)[0].link
  }

  const renderVideo = () => {
    if (getVideoLink().includes('youtube')) {
      return (
        <ReactPlayer url={getVideoLink()} controls />
      )
    } else if (getVideoLink().includes('instagram')) {
      return (
        <InstagramEmbed url={getVideoLink()} maxWidth={320} hideCaption={false} containerTagName='div' />
      )
    } else {
      return (
        <section className="modal__body">
          <video controls className="modal__video">
            <source
              src={getVideoLink()}
              type="video/mp4" />
          </video>
        </section>
      )
    }
  }

  return (
    <Modal show={showModal} onHide={handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Tip of the Month</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="user-tips__video--container">
          {renderVideo()}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default VideoOfTheMonth
