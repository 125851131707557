import React, {useState, useEffect, useContext, createContext} from 'react'
import {firebase} from '@firebase/app'

export const AuthContext = createContext()
export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged(user => setCurrentUser(user))
    } catch (e) {
      console.error('Error: ', e)
    }
  }, [])

  return (
    <AuthContext.Provider value={{currentUser}}>
      {children}
    </AuthContext.Provider>
  )
}
