import React, { useState, useEffect } from 'react'

import { sportsRoute, scoresRoute } from '../../utils/routes'
import axios from 'axios'
import Select from 'react-select'
import Venue from './sidebar/Venue'
import Filters from './sidebar/Filters'
import Pagination from './sidebar/Pagination'
import Scores from './sidebar/Scores'

const SideBar = props => {
  const {
    venues,
    selectedVenue,
    firebaseUid,
    scoreClick,
    setScoreClick
  } = props
  const [displayPagination, setDisplayPagination] = useState()

  useEffect(() => {
    openTab('filters', 'daasly-sidebar-nav-filters')
  }, [])

  useEffect(() => {
    if (scoreClick) {
      openNav()
      openTab('scores', 'daasly-sidebar-nav-scores')
      setScoreClick(false)
    }
  }, [scoreClick])

  const openNav = () => {
    document.getElementById('daasly-sidebar').style.width = '20rem'
    document.getElementById('daasly-sidebar-button').style.display = 'none'
  }

  const closeNav = () => {
    setTimeout(() => {
      document.getElementById('daasly-sidebar-button').style.display = 'flex'
    }, 500)
    document.getElementById('daasly-sidebar').style.width = '0'
  }

  const openTab = (tabName, parentTabName) => {
    const xBody = document.getElementsByClassName('daasly-sidebar-tab')
    for (const c of xBody) {
      c.style.display = 'none'
    }
    const xNav = document.getElementsByClassName('daasly-sidebar-nav')
    for (const c of xNav) {
      c.style.borderBottom = '1px black solid'
    }
    document.getElementById(tabName).style.display = 'block'
    document.getElementById(parentTabName).style.borderBottom =
      '1.5px red solid'
    if (tabName != 'filters') {
      setDisplayPagination(false)
    } else {
      setDisplayPagination(true)
    }
  }

  return (
    <div style={{ position: 'absolute', right: 0, top: 0, height: '100%' }}>
      <div id='daasly-sidebar-button' onClick={openNav}>
        <i className='fas fa-bars'></i>
      </div>
      <div id='daasly-sidebar'>
        {/* HEADER */}
        <div className='daasly-sidebar-header'>
          <div
            id='daasly-sidebar-nav-filters'
            className='daasly-sidebar-nav'
            onClick={() => {
              openTab('filters', 'daasly-sidebar-nav-filters')
            }}
          >
            Filters
          </div>
          <div
            id='daasly-sidebar-nav-venue'
            className='daasly-sidebar-nav'
            onClick={() => {
              openTab('venue', 'daasly-sidebar-nav-venue')
            }}
          >
            Venue
          </div>
          <div
            id='daasly-sidebar-nav-scores'
            className='daasly-sidebar-nav'
            onClick={() => {
              openTab('scores', 'daasly-sidebar-nav-scores')
            }}
          >
            Score
          </div>
          <div id='daasly-sidebar-nav-close' onClick={closeNav}>
            <i className='fas fa-times'></i>
          </div>
        </div>
        {/* BODY */}
        <div className='daasly-sidebar-body'>
          <Filters {...props} />
          <Venue {...props} />

          <Scores {...props} />
        </div>
        {/* FOOTER */}
        <div className='daasly-sidebar-footer'>
          {displayPagination ? <Pagination {...props} /> : null}
        </div>
      </div>
    </div>
  )
}

export default SideBar
