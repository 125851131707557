import React from 'react'
import ReactMapGL, {
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
  ScaleControl
} from 'react-map-gl'

const MapControls = props => {
  const { viewport } = props

  const geoLocation = viewport => {
    const geoLocation = (
      <div>
        <GeolocateControl
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          fitBoundsOptions={{ maxZoom: viewport.zoom }}
        />
      </div>
    )
    return geoLocation
  }

  const zoomControls = viewport => {
    const zoomControls = (
      <div>
        <NavigationControl showCompass={false} />
      </div>
    )
    return zoomControls
  }
  const fullScreen = () => {
    const fullScreen = (
      <div>
        <FullscreenControl />
      </div>
    )
    return fullScreen
  }

  return (
    <div className='daasly-map-controls'>
      {viewport ? geoLocation(viewport) : null}
      {viewport ? zoomControls(viewport) : null}
      {viewport ? fullScreen(viewport) : null}
    </div>
  )
}

export default MapControls
