import React from 'react'

const formatDate = v => {
  const date = new Date(v).toDateString()
  return date
}

const BowlingList = ({ venueScores }) => {
  return (
    <div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Date</th>
            <th scope='col'>Score</th>
          </tr>
        </thead>
        <tbody>
          {venueScores &&
            venueScores.map(r => (
              <tr key={r.id}>
                <td>{formatDate(r.created_at)}</td>
                <td>{r.total_score}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
export default BowlingList
