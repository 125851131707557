import React, { useState } from "react";
import { GOOGLE_CLOUD_STORAGE_PUBLIC_PATH } from "../utils";
import { Modal, Button } from "react-bootstrap";
import ReactPlayer from "react-player";

const NewsStories = (props) => {
  const [showCommercialVideo, toggleCommercialVideo] = useState(false);

  const handleClose = () => {
    toggleCommercialVideo(false);
  };

  const renderCommercialVideo = () => (
    <Modal show={showCommercialVideo} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Places2Play.com</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=uR6lWm43nJQ"
          playing={true}
          controls
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="news-stories__container">
      {showCommercialVideo && renderCommercialVideo()}

      <div className="news-stories__text--container">
        <h2 className="news-stories__header">News Stories</h2>

        <p className="news-stories__text">
          The Places2Play.com organization believes in giving back to the
          communities we serve, as well as partnerships that support small
          business. We are invested in helping people of all ages live a more
          active life, and giving children the opportunity to develop
          foundational life skills.
        </p>
      </div>

      <div className="news-stories__gallery">
        <a
          href="https://wsvn.com/news/local/north-bay-village-resident-raises-50000-for-furloughed-golf-club-employees"
          className="news-stories__gallery--item"
          target="blank"
        >
          <img
            className="news-stories__img"
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/news-story-1.jpg`}
          />
          <span className="news-stories__title">
            Places2Play.com CEO provides financial help to workers who have been
            laid off from two South Florida golf clubs.
          </span>
        </a>

        <div className="news-stories__gallery--item">
          <img
            className="news-stories__img"
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/news-story-2.jpg`}
          />
          <span className="news-stories__title">
            Places2Play.com founder Bobby Latham donating to Support Broward
            County back to school program.
          </span>
        </div>

        <div
          className="news-stories__gallery--item"
          onClick={() => toggleCommercialVideo(true)}
        >
          <img
            className="news-stories__img"
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/news-story-3.jpg?ignoreCache=1`}
          />
          <span className="news-stories__title">
            Watch the official Places2Play commercial!
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewsStories;
