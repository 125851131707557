import React, { useState, useEffect } from 'react'
import { sportsRoute } from '../../../utils/routes'
import axios from 'axios'
import Select from 'react-select'

const Filters = props => {
  const { venueParams, setVenueParams } = props
  const [sports, setSports] = useState([])
  const [leagues, setLeagues] = useState([{ name: 'adult' }, { name: 'kids' }])
  const [favorites, setFavorites] = useState([{ name: 'Yes' }, { name: 'No' }])
  const [played, setPlayed] = useState([{ name: 'Yes' }, { name: 'No' }])

  useEffect(() => {
    const getSports = () => {
      axios.get(sportsRoute).then(r => setSports(r.data))
    }
    getSports()
  }, [])

  const formatValues = filterValues => {
    if (!filterValues.length) return null
    return filterValues.map(r => {
      return { label: r.name, value: r.name }
    })
  }

  const handleLeagueSelection = data => {
    setVenueParams({ ...venueParams, league: data.value })
  }

  const handleSportSelection = data => {
    setVenueParams({ ...venueParams, sport: data.value })
  }

  const handleFavoriteSelection = data => {
    setVenueParams({ ...venueParams, favorite: data.value })
  }

  const handlePlayedSelection = data => {
    setVenueParams({ ...venueParams, played: data.value })
  }

  return (
    <div id='filters' className='daasly-sidebar-tab'>
      <div>League</div>
      <Select
        options={formatValues(leagues)}
        name='league'
        placeholder={venueParams.league}
        onChange={handleLeagueSelection}
      />
      <div>Sports</div>
      <Select
        options={formatValues(sports)}
        name='sport'
        onChange={handleSportSelection}
      />
      <div>Only Favorites</div>
      <Select
        options={formatValues(favorites)}
        name='favorite'
        placeholder={venueParams.favorite}
        onChange={handleFavoriteSelection}
      />
      <div>Only Played</div>
      <Select
        options={formatValues(played)}
        name='played'
        placeholder={venueParams.played}
        onChange={handlePlayedSelection}
      />
    </div>
  )
}

export default Filters
