import React, { useState, useEffect, useContext } from 'react'
import Map from './map/Map.jsx'
import axios from 'axios'
import {storage} from '../firebase'
import { currentUserRoute, userImageRoute } from '../utils/routes'
import {useDropzone} from 'react-dropzone'
import { AuthContext } from '../auth'
import FriendFinder from '../friend_finder'
import Nav from '../nav'
import Footer from '../footer'

const PlacesPlayed = () => {
  const { currentUser } = useContext(AuthContext)
  const [currentUserId, setCurrentUserId] = useState()
  const [imageFiles, setImageFiles] = useState([])
  const [userData, setUserData] = useState(null)
  const [showImageForm, toggleImageForm] = useState(false)
  const [userImage, setUserImage] = useState(null)
  const [progress, setProgress] = useState(0)
  const [showFriendFinder, toggleFriendFinder] = useState(false)
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImageFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  useEffect(() => {
    const getUserImage = () => {
      if (currentUser) {
        const params = {firebase_uid: currentUser.uid}
        axios.get(userImageRoute, {params})
          .then(r => r.data.success && setUserImage(r.data.image_url))
      }
    }
    const getUserData = () => {
      if (currentUser) {
        const params = {firebase_uid: currentUser.uid}
        axios.get(currentUserRoute, {params})
          .then(r => setUserData(r.data))
      }
    }
    getUserData()
    getUserImage()
  }, [currentUser])

  const getUserRailsID = user => {
    const firebase_uid = user.uid
    const params = {
      firebase_uid
    }
    axios.get(currentUserRoute, { params }).then(r => {
      setCurrentUserId(r.data.id)
    })
  }

  const renderUserDetails = () => {
    if (!userData) return null
    return (
      <ul className="user-profile__details--container">
        <div className="user-profile__data--container">
          <li className="user-profile__item">
            <span className="user-profile__item--content">
              {currentUser['displayName']}
            </span>
          </li>
        </div>

        {renderImgOrUpload()}

      </ul>
    )
  }

  const renderImgOrUpload = () => {
    if (userImage) { // user's uploaded image
      return (
        <div className="user-profile__avatar--container">
          <img src={userImage} className="places_played__avatar" />

          <span onClick={() => toggleImageForm(!showImageForm)} className="user-profile__img--link">
            Upload a new image
          </span>

          {showImageForm && renderImageForm()}
        </div>
      )
    } else if (currentUser && currentUser.photoURL) { // default img from Firebase
      return (
        <div className="user-profile__avatar--container">
          <img src={currentUser.photoURL} className="user-profile__avatar" />

          <span onClick={() => toggleImageForm(!showImageForm)} className="user-profile__avatar--link">
            Upload a new image
          </span>

          {showImageForm && renderImageForm()}
        </div>
      )
    } else {
      return (
        <div>
          You don't have a profile picture yet.
          {renderImageForm()}
        </div>
      )
    }
  }

  const renderImageForm = () => {
    return (
      <>
        <div className="u-margin-top-medium">
          <h4>Upload a new Profile Picture</h4>
          {progress === 100 ? <h3 className="upload-success">Your picture has been uploaded!</h3> : null}
          {progress === 100 ? <span className="upload-success--small">Please refresh the page</span> : null}
          {progress > 0 && <progress value={progress} max="100" className="images-progress-bar" />}
          <div className="modal__body picture-dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n' Drop an image here, or click to select a file.</p>
          </div>

          <button className="u-margin-top-small btn__large" onClick={onSubmit}>
            Add your Picture
          </button>
        </div>

        <aside className="u-margin-top-small">
          <ul>{thumbs}</ul>
        </aside>
      </>
    )
  }

  // TODO only render this function once social component is functioning correctly
  const renderFriends = () => {
    if (!userData) return null
    return (
      <div className="places_played__friends--text-container">
        <span className="places_played__friends--header">
          Friends
        </span>

        <span className="friends__finder--link" onClick={() => toggleFriendFinder(!showFriendFinder)}>
          Add Friend
        </span>

        <div className="places_played__friends--container">
          {renderFriendsAvatars()}
        </div>
      </div>
    )
  }

  const renderFriendsAvatars = () => {
    if (!userData) return null
    return userData.friends.map(f => {
      const friendKey = f.id + Math.random()*100
      return (
        <div key={friendKey} className="places_played__friend--container">
          <img src={f.image_url} className="places_played__friend--img" />
          <span className="user-profile__friend--name">{f.name || 'None'}</span>
        </div>
      )
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    uploadImageToFirebase(imageFiles)
  }

  const thumbs = imageFiles.map(file => (
    <div key={file.name} className="dropzone-thumb">
      <img src={file.preview} className="dropzone-preview-img" />
      <span className="u-margin-left-small">{file.name} - {file.size} bytes</span>
    </div>
  ))

  const uploadImageToFirebase = (imageFiles) => {
    imageFiles.forEach(imageFile => {
      const refPath = `places2play_public/users/${currentUser.uid}/`
      const fileName = imageFile.name.replace(/ +/g, "-")
      const uploadTask = storage.ref(refPath).child(fileName).put(imageFile)

      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {console.log('Error: ', error)},
        () => {
          storage
            .ref(refPath)
            .child(fileName)
            .getDownloadURL()
            .then(image_url => {
              const params = {image_url, firebase_uid: currentUser.uid}
              axios.post(userImageRoute, params)
                .then(r => r.data.success && setUserImage(r.data.user.image_url) && window.location.reload(true))
            })
        }
      )
    })
  }

  return (
    <>
      <div>
        <Nav />

        <div className='places_played__user-profile--container'>
          {renderUserDetails()}
          {showFriendFinder && <FriendFinder />}
        </div>

        <div className='places_played__map--container'>
          <Map firebaseUid={currentUser?.uid} />
        </div>

        <Footer />
      </div>
    </>
  )
}

export default PlacesPlayed
