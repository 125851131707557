import React from "react";
import { GOOGLE_CLOUD_STORAGE_PUBLIC_PATH } from "../utils";

const Testimonials = (props) => {
  return (
    <div className="testimonials__container pt-5">
      <h2 className="testimonials__header">Testimonials</h2>

      <div className="testimonials__text--container">
        <div className="testimonial__container">
          <p className="testimonials__text">
            <span className="testimonials__quote">"</span>So glad I found this
            website! It always feels like mission impossible getting my kids
            sports and after school stuff organized. Now we have a one stop shop
            for our family activities... thank you!
            <span className="testimonials__quote">"</span>
          </p>

          <p className="testimonials__author">Megan F.</p>

          <img
            className="testimonials__img u-margin-top-small"
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/testimonial-1.png`}
          />
        </div>

        <div className="testimonial__container">
          <p className="testimonials__text">
            <span className="testimonials__quote">"</span>When I moved to South
            Florida, I was looking to meet new people while playing sports I
            loved. Places2Play helped me with both.
            <span className="testimonials__quote">"</span>
          </p>

          <p className="testimonials__author">Steven J.</p>

          <img
            className="testimonials__img u-margin-top-small"
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/testimonial-2.png?ignoreCache=1`}
          />
        </div>

        <div className="testimonial__container">
          <p className="testimonials__text">
            <span className="testimonials__quote">"</span>My boy’s school uses
            Places2Play to manage the afterschool activities. I can get real
            time updates on cancelations and changes to his karate club.
            <span className="testimonials__quote">"</span>
          </p>

          <p className="testimonials__author">Andrea P.</p>

          <img
            className="testimonials__img u-margin-top-small"
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/testimonial-3.png`}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
