import React, { useState, useEffect } from 'react'

const Pagination = props => {
  const { venueParams, setVenueParams, venues } = props

  useEffect(() => {}, [venueParams])

  const nextPage = venueParams.page ? Number(venueParams.page) + 1 : 1
  const prevPage = venueParams.page > 0 ? Number(venueParams.page) - 1 : ''

  const handlePrev = page => {
    setVenueParams({ ...venueParams, page: prevPage })
  }

  const handleNext = page => {
    setVenueParams({ ...venueParams, page: nextPage })
  }

  const renderPrevBtn = () => {
    return venueParams.page < 1 ? (
      <i id='daasly-sidebar-footer-prev'></i>
    ) : (
      <i
        id='daasly-sidebar-footer-prev'
        onClick={handlePrev}
        className='fal fa-arrow-square-left'
      ></i>
    )
  }

  const renderNoResults = () => {
    return venues.length === 0 ? <div>No Results</div> : null
  }

  const renderNextBtn = () => {
    return venues.length === 0 ? (
      <i id='daasly-sidebar-footer-next'></i>
    ) : (
      <i
        id='daasly-sidebar-footer-next'
        onClick={handleNext}
        className='fal fa-arrow-square-right'
      ></i>
    )
  }

  return (
    <>
      <div className='daasly-sidebar-footer-noresults'>{renderNoResults()}</div>
      <div className='daasly-sidebar-footer-page'>
        {renderPrevBtn()}
        <div>{`Page ${venueParams.page + 1}`}</div>
        {renderNextBtn()}
      </div>
    </>
  )
}

export default Pagination
