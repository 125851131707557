import { MapController } from 'react-map-gl'

export default class MyMapController extends MapController {
  constructor(setMapCenter) {
    super()
    this.setMapCenter = setMapCenter
    this.events = ['panend']
  }

  handleEvent(event) {
    if (event.type === 'panend') {
      const mapState = this.getMapState()
      const centerLat = mapState._viewportProps.latitude
      const centerLong = mapState._viewportProps.longitude

      this.setMapCenter({
        lat: centerLat,
        long: centerLong
      })
    }
    return super.handleEvent(event)
  }
}
