import Softball from '../../images/map_icons/Softball.svg'
import Baseball from '../../images/map_icons/Baseball.svg'
import Golf from '../../images/map_icons/Golf.svg'
import Basketball from '../../images/map_icons/Basketball.svg'
import Football from '../../images/map_icons/Football.svg'
import Tennis from '../../images/map_icons/Tennis.svg'
import Soccer from '../../images/map_icons/Soccer.svg'
import Hockey from '../../images/map_icons/Hockey.svg'
import Swimming from '../../images/map_icons/Swimming.svg'
import Volleyball from '../../images/map_icons/Volleyball.svg'
import Yoga from '../../images/map_icons/Yoga.svg'
import Gymnastics from '../../images/map_icons/Gymnastics.svg'
import Diving from '../../images/map_icons/WaterSports.svg'
import Sailing from '../../images/map_icons/Sailing.svg'
import Skiing from '../../images/map_icons/Skiing.svg'
import Running from '../../images/map_icons/Running.svg'
import Boxing from '../../images/map_icons/Boxing.svg'
import Gym from '../../images/map_icons/Gym.svg'
import MartialArts from '../../images/map_icons/MartialArts.svg'
import Polo from '../../images/map_icons/Polo.svg'
import Rowing from '../../images/map_icons/Rowing.svg'
import Rugby from '../../images/map_icons/Rugby.svg'
import Camping from '../../images/map_icons/Camping.svg'
import Bowling from '../../images/map_icons/Bowling.svg'
import WaterSports from '../../images/map_icons/WaterSports.svg'
import TableTennis from '../../images/map_icons/Tennis.svg'
import CrossCountry from '../../images/map_icons/Running.svg'
import Walking from '../../images/map_icons/Running.svg'
import Track from '../../images/map_icons/Running.svg'
import Poker from '../../images/map_icons/Poker.svg'
import Dodgeball from '../../images/map_icons/Dodgeball.svg'
import Cycling from '../../images/map_icons/Cycling.svg'
import Climbing from '../../images/map_icons/RockClimbing.svg'
import Karate from '../../images/map_icons/MartialArts.svg'
import Paintball from '../../images/map_icons/Paintball.svg'
import Fishing from '../../images/map_icons/Fishing.svg'
import Other from '../../images/map_icons/Other.svg'
import Music from '../../images/map_icons/Music.svg'
import Playground from '../../images/map_icons/Playground.svg'
import Dance from '../../images/map_icons/Dance.svg'

export default {
  "Softball": Softball,
  "Baseball": Baseball,
  "Golf": Golf,
  "Basketball": Basketball,
  "Football": Football,
  "Tennis": Tennis,
  "Soccer": Soccer,
  "Hockey": Hockey,
  "Swimming": Swimming,
  "Volleyball": Volleyball,
  "Yoga": Yoga,
  "Gymnastics": Gymnastics,
  "Diving": Diving,
  "Sailing": Sailing,
  "Skiing": Skiing,
  "Running": Running,
  "Boxing": Boxing,
  "Gym": Gym,
  "MartialArts": MartialArts,
  "Polo": Polo,
  "Rowing": Rowing,
  "Rugby": Rugby,
  "Camping": Camping,
  "Bowling": Bowling,
  "WaterSports": WaterSports,
  "TableTennis": TableTennis,
  "CrossCountry": CrossCountry,
  "Walking": Walking,
  "Track": Track,
  "Poker": Poker,
  "Dodgeball": Dodgeball,
  "Cycling": Cycling,
  "Climbing": Climbing,
  "Karate": Karate,
  "Paintball": Paintball,
  "Fishing": Fishing,
  "Other": Other,
  "Music": Music,
  "Playground": Playground,
  "Dance": Dance,
}

