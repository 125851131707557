import React from 'react'

const Cover = (props) => {
  const { handleMarkerClick } = props

  // Creates overlay to close pop-up once clicking out, will prevrent scrolling while pop-up is open.
  // Awiting customer confirmation to add or remove code.
  // <div
  //     id="div1"
  //     style={{
  //       height: viewport.height,
  //       width: viewport.width,
  //       position: 'absolute',
  //       zIndex: '-1',
  //       top: '-100%',
  //       left: '-100%'
  //     }}
  //     onClick={() => {
  //       handleMarkerClick(null)
  //     }}
  //   ></div>

  return (
    <button
      className="daasly-popup-close-cover mapboxgl-popup-close-button"
      onClick={() => {
        handleMarkerClick(null)
      }}
    >
      <i className="fas fa-times daasly-popup-close-button-icon" />
    </button>
  )
}

export default Cover
