import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Carousel} from 'react-bootstrap'
import {schoolRoute} from '../utils/routes'
import {storage} from '../firebase'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import Nav from '../nav'
import StarRating from './star_rating'
import Footer from '../footer'

const SchoolsShow = ({match}) => {
  const [school, setSchool] = useState()
  const [imageURLs, setImageURLs] = useState({})

  useEffect(() => {
    const getSchool = () => {
      const schoolCode = match.params.school_code
      axios.get(schoolRoute + `/${schoolCode}`).then(r => {
        if (r.data) setSchool(r.data)
      })
    }
    getSchool()
  }, [])

  useEffect(() => {
    getImagesFromFirebase()
  }, [school])

  const getImagesFromFirebase = () => {
    if (!school) return null

    return school.school_programs.map(program => {
      var listRef = storage.ref().child(`places2play_public/after-school-programs/${program.image_folder}/`)

      var programURLs = []
      listRef.listAll().then(res => {
        res.items.forEach(imageRef => {
          imageRef.getDownloadURL().then(url => {
            programURLs.push(url)
            setImageURLs(prevState => ({...prevState, [program.id]: programURLs}))
          })
        })
      }).catch(error => console.log('Error: ', error))
    })
  }

  const renderProgramImages = (program) => {
    if (!imageURLs[program.id]) return null

    return imageURLs[program.id].map(url => {
      return (
        <Carousel.Item key={url} className="school__carousel--item">
          <img src={url} className="school__program--img" />
        </Carousel.Item>
      )
    })
  }

  const renderAfterSchoolPrograms = school => {
    if (!school) return null

    return school.school_programs.map(p => {
      return (
        <div className="school__program--container" key={p.id}>
          <Carousel>
            {renderProgramImages(p)}
          </Carousel>

          <div className="school__program--text-container">
            <h2 className="u-margin-top-small">{p.name}</h2>

            <StarRating />
            <p>
              <span className="u-margin-right-xs">{renderLeague(p)}</span>
              | <span className="u-margin-left-xs">{p.age_range}</span>
            </p>

            <p>
              {p.day_offered}<br />
              {p.time_offered}<br />
              Contact: {p.organizer}
            </p>

            <p className="u-margin-top-xs">
              {p.monthly_fee ? `Monthly Fee: $${p.monthly_fee}` : null}
            </p>

            <div className="school__btns--container">
              <a href={`mailto:${p.contact_email}?subject=Interested in ${p.name} at ${school.name}`} target="blank">
                <button className="school__email-btn">Email</button>
              </a>

              <a href={p.registration_link || '#'} target="blank">
                <button className="school__register-btn">Register</button>
              </a>
            </div>
          </div>

        </div>
      )
    })
  }

  const renderLeague = ({boys_league, girls_league}) => {
    if (boys_league && girls_league) {
      return <span className="school__league">Boys & Girls</span>
    } else if (boys_league) {
      return <span className="school__league">Boys</span>
    } else if (girls_league) {
      return <span className="school__league">Girls</span>
    }
  }

  return (
    <div className="school__container">
      <div className="school__header--container">
        <Nav style="light"/>
        <h1 className="header">School Code</h1>
      </div>

      <div className="school__content--container">

        <div className="school__secondary-header--container">
          <span className="school__code">#{school?.school_code}</span>

          <div className="school__details--container">
            <span className="school__secondary--header">{school?.name}</span>
            <span className="school__tertiary--header u-margin-top-xs">{school?.street_address}</span>

            <span className="school__tertiary--header">
              {school?.city}, {school?.state}
            </span>

            <span className="school__tertiary--header">
              {school?.zip_code}
            </span>
          </div>
        </div>

        <div className="school__programs--container">
          {renderAfterSchoolPrograms(school)}
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default SchoolsShow
