import React, { useState, useEffect } from 'react'
import { Marker, FlyToInterpolator } from 'react-map-gl'
import Popup from './Popup'

const MapMarker = props => {
  const { venues, handleMarkerClick, setViewport, animation } = props
  // TODO convert this array to a better data structure to map sports to their icons
  // TODO add remaining new sports and find icons from fontawesome
  const [markers, setMarkers] = useState([
    // Baseball
    'fas fa-baseball-ball',
    // Softball
    'fas fa-baseball-ball',
    // Golf
    'fas fa-golf-ball ',
    // Basketball
    'fas fa-basketball-ball',
    // Football
    'fas fa-football-ball',
    // Tennis
    'fas fa-raquet',
    // Soccer
    'fas fa-futbol',
    // Hockey
    'fas fa-hockey-puck',
    // Swimming
    'fas fa-swimmer',
    // Volleyball
    'fas fa-volleyball-ball',
    // Yoga
    'fas fa-spa',
    // Gymnastics
    'fas fa-walking',
    // Diving
    'fas fa-swimmer',
    // Sailing
    'fas fa-ship',
    // Dodgeball
    'fas fa-futbol',
    // Running
    'fas fa-running',
    // Boxing
    'fas fa-boxing-glove',
    // Gym
    'fas fa-dumbbell',
    // MartialArts
    'fas fa-boxing-glove',
    // Polo
    'fas fa-horse',
    // Rowing
    'fas fa-water',
    // Rugby
    'fas fa-football-ball',
    // Camping
    'fas fa-campground',
    // Bowling
    'fas fa-bowling-ball',
    // Tabletennis
    'fas fa-table-tennis',
    // CrossCountry
    'fas fa-running',
    // Walking
    'fas fa-shoe-prints',
    // Racquetball
    'fas fa-raquet',
    // Track
    'fas fa-running',
    // Cycling
    'fas fa-biking',
    // Paintball
    'fas fa-meteor'
  ])

  const setInnerMarker = (sportId, venueId) => {
    const marker = markers.filter((m, index) => index === sportId - 1)
    return (
      <div
        id={`marker-${venueId}`}
        className={`${marker} daasly-marker-inner daasly-marker-red`}
      />
    )
  }

  const setInnerGreenMarker = (sportId, venueId) => {
    const marker = markers.filter((m, index) => index === sportId - 1)
    return (
      <div
        id={`marker-${venueId}`}
        className={`${marker} daasly-marker-inner daasly-marker-green`}
      />
    )
  }

  const setPrimaryColor = venue => {
    if (venue.is_played) {
      return (
        <div
          className='fas fa-map-marker daasly-marker-outer'
          onClick={() => {
            handleMarkerClick(venue)
            setViewport({
              longitude: Number(venue.longitude),
              latitude: Number(venue.latitude),
              zoom: 12,
              transitionDuration: 800,
              transitionInterpolator: new FlyToInterpolator()
            })
          }}
        >
          {setInnerGreenMarker(venue.sport_id, venue.id)}
        </div>
      )
    } else {
      return (
        <div
          className='fas fa-map-marker daasly-marker-outer'
          onClick={() => {
            handleMarkerClick(venue)
            setViewport({
              longitude: Number(venue.longitude),
              latitude: Number(venue.latitude),
              zoom: 12,
              transitionDuration: 800,
              transitionInterpolator: new FlyToInterpolator()
            })
          }}
        >
          {setInnerMarker(venue.sport_id, venue.id)}
        </div>
      )
    }
  }

  const renderVenues = venues => {
    const markers = props.venues.map(venue => {
      return (
        <Marker
          key={venue.id}
          data-key={venue.id}
          latitude={Number(venue.latitude)}
          longitude={Number(venue.longitude)}
          offsetTop={-45} // negative number is equal to height of div
          offsetLeft={-16.875} // negative number is equal to 1/2 the width of div
        >
          {setPrimaryColor(venue)}
        </Marker>
      )
    })
    return markers
  }

  return (
    <div>
      {venues ? renderVenues(venues) : null}
    </div>
  )
}

export default MapMarker
