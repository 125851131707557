import React from 'react'
import Nav from '../nav'
import { Link } from 'react-router-dom'
import Map from '../map/index'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import {Carousel} from 'react-bootstrap'

const SelectLeague = (props) => {
  const renderCarouselItems = () => {
    var imgSrcs = [['header-karate.png', 'header-soccer.png'],
                  ['header-snowboard.png', 'header-polo.png'],
                  ['header-baseball.png', 'header-hockey.png']]

    return imgSrcs.map(images => {
      const leftImg = images[0]
      const rightImg = images[1]
      const imgKey = Math.floor(Math.random()*1000)

      return (
        <Carousel.Item key={imgKey}>
          <div className="carousel__images--container">
            <img
              className="select_league__left-img"
              src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/${leftImg}`}
            />
            <img
              className="select_league__right-img"
              src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/${rightImg}`}
            />
          </div>
        </Carousel.Item>
      )
    })
  }

  return (
    <>
      <div className="select_league__header--area">
        <Nav />

        <Carousel interval={2900} wrap={true}>
          {renderCarouselItems()}
        </Carousel>

        <div className="select_league__text--container">
          <h1 className="select_league__text">
            Find Sports
            <span className="select_league__text--btm">Near you</span>
          </h1>

          <div className="select_league__btn--container">
            <Link to="/adults_league_sports">
              <button className="btn__standard">Adult Sports</button>
            </Link>

            <Link to="/kids_league_sports">
              <button className="btn__standard">Kids Sports</button>
            </Link>

          </div>

        </div>
      </div>
    </>
  )
}

export default SelectLeague
