import React from "react";
import SelectLeague from "../select_league";
import SchoolSportsSimplified from "../school_sports_simplified";
import NewsStories from "../news_stories";
import Testimonials from "../testimonials";
import EnterToWin from "../enter_to_win";
import AppDownload from "../app_download";
import Footer from "../footer";

const HomePage = (props) => {
  console.log("HERE", process.env.FIREBASE_AUTH_DOMAIN);
  return (
    <>
      <SelectLeague />
      <EnterToWin />
      <SchoolSportsSimplified />
      <AppDownload />
      <NewsStories />
      <Testimonials />
      <Footer />
    </>
  );
};

export default HomePage;
