import React from 'react'
import AboutUs from './index'
import Footer from '../footer'
import Nav from '../nav'

const AboutUsContainer = props => {
  return (
    <>
      <div className="about-us__nav--container">
        <Nav />
      </div>

      <AboutUs />
      <Footer />
    </>
  )
}

export default AboutUsContainer
