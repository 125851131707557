import React from 'react'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import fbIcon from '../images/miscellaneous_icons/fb-icon-solid.png'
import instagramIcon from '../images/miscellaneous_icons/instagram-icon-solid.png'
import twitterIcon from '../images/miscellaneous_icons/twitter-icon-solid.png'

const AppDownload = props => {
  return (
    <div className="app-download__container">
      <img
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/computer.png`}
          className="app-download__image"
      />

      <div className="app-download__text--container">
        <h1 className="app-download__header">
          Now <br />
          Available On
        </h1>

        <img
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/app-store-link.png`}
          className="app-download__icon"
        />

        <p className="app-download__follow">Follow us</p>

        <div>
          <a href="https://www.facebook.com/places2playcom-123013841103011" target="_blank">
            <img src={fbIcon} className="app-download__social--icon" />
          </a>

          <a href="https://twitter.com/places2play" target="_blank">
            <img src={twitterIcon} className="app-download__social--icon u-margin-left-small" />
          </a>

          <a href="https://www.instagram.com/places2play_com/" target="_blank">
            <img src={instagramIcon} className="app-download__social--icon u-margin-left-small" />
          </a>
        </div>

      </div>

    </div>
  )
}

export default AppDownload
