import React from 'react'

const Spinner = props => {
  return (
    <div className="spinner-border text-primary">
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default Spinner
