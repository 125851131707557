import React, {useState} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import VideoOfTheMonth from './video_of_the_month'
import {currentTipOfMonthRoute} from '../utils/routes'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import {sportsVideos} from '../utils'

const UserTips = ({match, history, sport}) => {
  const [showVideo, toggleVideo] = useState(false)
  const [tipOfTheMonth, setTipOfTheMonth] = useState(false)
  const { league } = match.params

  const getThumbnail = () => {
    return sportsVideos.filter(videoObj => videoObj.sport === sport)[0]?.thumbnail
  }

  const renderTipOfTheMonth = () => {
    return (
      <>
        <img
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/tip-of-the-month/thumbnails/${getThumbnail()}`}
          className="user-tips__screenshot"
          onClick={() => toggleVideo(!showVideo)} />
        <h6 className="u-margin-top-xs">{sport} Tip of the Month</h6>
      </>
    )
  }

  return (
    <div className="user-tips__container">
      {showVideo && <VideoOfTheMonth sport={sport}/>}

      <div className="user-tips__item--container">
        {renderTipOfTheMonth()}
      </div>

      <div className="user-tips__item--container">
        <Link to="/places_played">
          <img
            src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/map_screenshot.png`}
            className="user-tips__screenshot"/>
        </Link>
        <h6 className="u-margin-top-xs">Where have you played?</h6>
      </div>

    </div>
  )
}

export default UserTips
