import React, { useState, useContext, useEffect } from 'react'
import {axiosHelper} from '../utils/index'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import {currentUserRoute, notificationsRoute} from '../utils/routes'
import { AuthContext } from '../auth'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from '../firebase'
import Notifications from '../notifications'

const Login = props => {
  let history = useHistory()
  const [showLoginForm, toggleLoginForm] = useState(false)
  const [showSchoolCodeForm, toggleSchoolCodeForm] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [friendRequests, setFriendRequests] = useState([])
  const [showNotifications, toggleNotifications] = useState(false)
  const {currentUser} = useContext(AuthContext)
  const {register, handleSubmit} = useForm()

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: ({user}) => {
        const {displayName, email, uid} = user
        var params = {displayName, email, firebase_uid: uid}
        axiosHelper()
        axios.post(currentUserRoute, params).then(r => {

          if (r.data.success) {
            params = {firebase_uid: currentUser.uid}

            axios.post(notificationsRoute, params)
            setFriendRequests(r.data.friend_requests)
            toggleNotifications(true)
          }
        })
      }
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  }

  useEffect(() => {
    currentUser && currentUser.uid ? setLoggedIn(true) : null
    renderSignInOutBtn()
  }, [currentUser, loggedIn])

  const logout = () => {
    const shouldLogout = confirm("Do you want to log out?")
    if (shouldLogout) {
      firebase.auth().signOut().then(r => {
        alert("You signed out.")
        setLoggedIn(false)
        window.location.reload()
      })
    }
  }

  const renderSignInOutBtn = () => {
    if (currentUser) {
      return (
        <div className="btn__signin" onClick={logout}>
          <span id="login" className="btn__signin--text">
            Sign Out
          </span>
        </div>
      )
    } else {
      return (
        <div className="btn__signin" onClick={() => toggleLoginForm(!showLoginForm)}>
          <span id="login" className="btn__signin--text">
            Sign In
          </span>
        </div>
      )
    }
  }

  const submitSchoolCode = (data, e) => {
    e.preventDefault()
    var schoolCode = data['school-code']
    history.push(`/school/${schoolCode}`)
  }

  return (
    <>
      {showNotifications && <Notifications data={{type: 'friendRequests', friendRequests}} />}

      {renderSignInOutBtn()}

      <div className="btn__signup" onClick={() => toggleLoginForm(!showLoginForm)}>
        <span className="btn__signin--text">Sign Up</span>
      </div>

      <div className="btn__school-code" onClick={() => toggleSchoolCodeForm(!showSchoolCodeForm)}>
        <span className="btn__signin--text">School Code</span>
      </div>

      {showSchoolCodeForm && (
        <div className="login__container">
          <span className="login__school-code">Enter a School Code</span>

          <form onSubmit={handleSubmit(submitSchoolCode)} className="login__school-code--form">
            <input type="text" name="school-code" className="login__school-code--input" ref={register} />
            <button onClick={handleSubmit}>Go</button>
          </form>
        </div>
      )}

      {showLoginForm && (
        <div className="login__container">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      )}
    </>
  )
}

export default Login
