import React, { useState, useEffect } from 'react'
import { Marker, FlyToInterpolator } from 'react-map-gl'
import MapPopup from './MapPopup'
import iconsObject from './SportIcons.jsx'

const MapMarker = (props) => {
  const { venues, handleMarkerClick, setViewport } = props

  const setInnerMarker = (sportName) => {
    if (!sportName) return null
    if (sportName.includes(" ")) {
      sportName = sportName.replace(" ", "")
    }

    let iconSrc = iconsObject[sportName]
    return <img src={iconSrc} width={'50px'} />
  }

  const setPrimaryColor = (venue) => {
    if (venue.is_played) {
      return (
        <div
          className="fas fa-map-marker daasly-marker-outer daasly-marker-green"
          onClick={() => {
            handleMarkerClick(venue)
          }}
        >
          {setInnerMarker(venue.sport_name)}
        </div>
      )
    } else {
      return (
        <div
          className="fas fa-map-marker daasly-marker-outer daasly-marker-red"
          onClick={() => {
            handleMarkerClick(venue)
            setViewport({
              longitude: Number(venue.longitude),
              latitude: Number(venue.latitude),
              zoom: 12,
              transitionDuration: 800,
              transitionInterpolator: new FlyToInterpolator()
            })
          }}
        >
          {setInnerMarker(venue.sport_name)}
        </div>
      )
    }
  }

  const getRender = (venues) => {
    const marker = props.venues.map((venue) => {
      return (
        <Marker
          key={venue.id}
          latitude={Number(venue.latitude)}
          longitude={Number(venue.longitude)}
          offsetTop={-45} // negative number is equal to height of div
          offsetLeft={-16.875} // negative number is equal to 1/2 the width of div
        >
          {setPrimaryColor(venue)}
        </Marker>
      )
    })
    return marker
  }

  return (
    <div>
      {venues ? getRender(venues) : null}
      <MapPopup {...props} />
    </div>
  )
}

export default MapMarker
