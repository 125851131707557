import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { favoriteSportsVenues } from '../../utils/routes'

const Favorite = (props) => {
  const { selectedVenue, toggleInteraction, currentUserId } = props

  useEffect(() => {
    setColor(selectedVenue.is_favorite)
  }, [selectedVenue])

  const setColor = (a) => {
    const setColorElement = document.getElementById('daasly-popup-favorite')
    const setClass = setColorElement.classList
    setColorElement && a === true ? setColorTrue(setClass) : setColorFalse(setClass)
  }

  const setColorTrue = (a) => {
    a.add('daasly-icon-gold')
    a.remove('daasly-icon-default')
  }

  const setColorFalse = (a) => {
    a.add('daasly-icon-default')
    a.remove('daasly-icon-gold')
  }

  const postAlert = (show, type, text) => {
    const params = { show, type, text }
    setMapAlert({ ...params })
    setTimeout(() => {
      setMapAlert({ show: false })
    }, 1500)
  }

  return (
    <div>
      {selectedVenue ? (
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Favorite</Tooltip>}>
          <button
            className="daasly-popup-favorite"
            onClick={() => {
              if (currentUserId) {
                toggleInteraction(selectedVenue.id, 'favorite')
                const text = selectedVenue.is_favorite
                  ? 'Removed from your Favorites list.'
                  : 'Added to Favorites list!'
                postAlert(true, 'secondary', text)
              } else {
                postAlert(true, 'secondary', 'Please Sign in')
              }
            }}
          >
            <i id="daasly-popup-favorite" className="fas fa-star fa-star-default" />
          </button>
        </OverlayTrigger>
      ) : null}
    </div>
  )
}

export default Favorite
