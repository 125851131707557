import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {FaStar} from 'react-icons/fa'
import {useAuthContext} from '../auth'
import {getRatingRoute} from '../utils/routes'

const StarRating = ({setRating, ratable, ratableType}, ref) => {
  const [hover, setHover] = useState(null)
  const [rating, setSelfRating] = useState(null)
  const {currentUser} = useAuthContext()

  useEffect(() => {
    const getUserVenueRating = () => {
      if (currentUser && ratable) {
        const review = {
          firebase_uid: currentUser.uid,
          reviewable_id: ratable.id,
          reviewable_type: ratableType
        }
        axios.post(getRatingRoute, {review}).then(r => {
          if (r.data.success) {
            setSelfRating(r.data.review.stars)
          }
        })
      }
    }
    getUserVenueRating()
  }, [currentUser])

  return (
    <div>
      {[...Array(5)].map((star, i) => {
        const starRatingValue = i + 1

        return (
          <label key={starRatingValue}>
            <input
              type="radio"
              name="rating"
              className="star-rating"
              value={starRatingValue}
              onClick={() => {
                setSelfRating(starRatingValue)
                setRating(starRatingValue, ratable)
              }}
              ref={ref}
            />
            <FaStar
              size={25}
              className="star"
              color={starRatingValue <= (hover || rating) ? "#ffc107" : ""}
              onMouseEnter={() => setHover(starRatingValue)}
              onMouseLeave={() => setHover(null)}
            />
          </label>
        )
      })}
    </div>
  )
}

const forwardedStarRating = React.forwardRef(StarRating)

export default forwardedStarRating
