import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Select from 'react-select'
import { sportsRoute, sportsVenueRoutes as venueRoute } from '../utils/routes'
import PictureForm from './picture_form'
import {leagueOptions} from '../utils'

const SportsVenueSignUp = props => {
  const { register, handleSubmit } = useForm()
  const [ sportOptions, setSportOptions ] = useState([])
  const [ sport, setSport ] = useState() // options for select
  const [ league, setLeague ] = useState()
  const [ venue, setVenue ] = useState({})
  const [showPictureForm, togglePictureForm] = useState(false)

  const createSportOptions = sportObjs => {
    // Format response objects into value/label objs for react-select
    setSportOptions(sportObjs.map(obj => {
      return {value: obj.id, label: obj.name}
    }))
  }

  useEffect(() => {
    const getSports = () => {
      axios.get(sportsRoute).then(r => createSportOptions(r.data))
    }
    getSports()
  }, [])

  const onSubmit = ({ name, phone, email, street_address, city, zip_code, state, website }, e) => {
    e.preventDefault();

    const payload = {
      sport_id: sport.value,
      league: league.value,
      phone,
      email,
      street_address,
      city,
      state,
      zip_code,
      website,
      name
    }

    axios.post(venueRoute, {...payload}).then(r => {
      setVenue(r.data)
      document.getElementById('signup-form').reset()
      alert("Your business was added to Places 2 Play." + "\n\n" + "Would you like to add pictures for this business?")
      togglePictureForm(!showPictureForm)
    })
  }

  return (
    <div className="sports-venue__signup__container">
      {showPictureForm && <PictureForm venue={venue}/>}

      <div className="sports-venue__signup__form-container">
        <h3>Add Your Business Details</h3>
        <form id="signup-form" onSubmit={handleSubmit(onSubmit)} className="sports-venue__signup__form">

          <div className="sports-venue__signup__form-group">
            <label htmlFor="name" className="sports-venue__signup__form-label">Business Name</label>
            <input
              type="text"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="YMCA, Bonaventure Country Club..."
              ref={register}
              name="name"
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="sport" className="sports-venue__signup__form-label">Sport Category</label>
            <Select
              options={sportOptions}
              className="sports-venue__signup__form-select u-margin-top-small"
              ref={register}
              onChange={sport => setSport(sport)}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="league" className="sports-venue__signup__form-label">League</label>
            <Select
              options={leagueOptions}
              className="sports-venue__signup__form-select u-margin-top-small"
              ref={register}
              onChange={league => setLeague(league)}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="phone" className="sports-venue__signup__form-label">Phone Number</label>
            <input
              type="tel"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="(555) 555-5555"
              name="phone"
              ref={register}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="url" className="sports-venue__signup__form-label">Website</label>
            <input
              type="url"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="www.companyaddress.com"
              name="website"
              ref={register}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="street_address" className="sports-venue__signup__form-label">Address</label>
            <input
              type="text"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="123 Main St"
              name="street_address"
              ref={register}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="city" className="sports-venue__signup__form-label">City</label>
            <input
              type="text"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="City"
              name="city"
              ref={register}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="state" className="sports-venue__signup__form-label">State</label>
            <input
              type="text"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="State"
              name="state"
              ref={register}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="zip_code" className="sports-venue__signup__form-label">Zip Code</label>
            <input
              type="text"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="Zip Code"
              name="zip_code"
              ref={register}
            />
          </div>

          <div className="sports-venue__signup__form-group">
            <label htmlFor="email" className="sports-venue__signup__form-label">Email</label>
            <input
              type="email"
              className="sports-venue__signup__form-input u-margin-top-small"
              placeholder="name@yourbusiness.com"
              name="email"
              ref={register}
            />
          </div>

          <button onClick={handleSubmit} className="sports-venue__signup__form-button">
            Add Business
          </button>
          <span className="sports-venue__signup--small-text">
            You'll be able to add pictures after you save.
          </span>
        </form>
      </div>
    </div>
  )
}

export default SportsVenueSignUp
