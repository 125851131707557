import React, {useContext, useState, useEffect} from 'react'
import axios from 'axios'
import logo from 'images/places-2-play-logo.png'
import { Badge } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import defaultAvatar from 'images/default-avatar.png'
import { Link } from 'react-router-dom'
import Login from '../login'
import { AuthContext } from '../auth'
import {userImageRoute, notificationsCountRoute} from '../utils/routes'
import Notifications from '../notifications'

const Nav = ({history, style}) => {
  const [userImage, setUserImage] = useState()
  const [showNotifications, toggleNotifications] = useState(false)
  const [notificationCount, setNotificationCount] = useState()
  const {currentUser} = useContext(AuthContext)

  useEffect(() => {
    let source = axios.CancelToken.source()

    const getUserImage = () => {
      if (currentUser) {
        const params = {firebase_uid: currentUser.uid}

        try {
          axios.get(userImageRoute, {params, cancelToken: source.token})
            .then(r => {
              r.data.success && setUserImage(r.data.image_url)
              axios.get(notificationsCountRoute, {params}).then(r => {
                r.data.success && setNotificationCount(r.data.count)
              })
            })
        } catch (e) {
          if (axios.isCancel(e)) {
            console.log('caught cancel error from axios request')
          } else {
            throw e
          }
        }
      }
    }

    getUserImage()

    return () => {
      source.cancel()
    }
  }, [currentUser])

  const renderUserImage = () => {
    if (currentUser && userImage) {
      return <img src={userImage} className="nav__profile--picture" />
    } else if (currentUser && currentUser.photoURL) {
        return <img src={currentUser.photoURL} className="nav__profile--picture" />
    } else {
      return <img src={defaultAvatar} className="nav__profile--picture" />
    }
  }

  return (
    <div className="nav__container" >
      <Link to="/"><img src={logo} className="logo"/></Link>

      <div className="nav-links__container">
        <Link to="/">
          <span className={`nav-link__item nav-link__item--${style}`}>Home</span>
        </Link>

        <Link to="/offers">
          <span className={`nav-link__item nav-link__item--${style}`}>Offers</span>
        </Link>

        <a href="mailto:contact@places2play.com?subject=New Message From Website">
          <span className={`nav-link__item nav-link__item--${style}`}>
            Contact Us
          </span>
        </a>
      </div>

      <Link to="/profile">
        <span className="nav-link__img">
          {renderUserImage()}
        </span>
      </Link>

      <Login />

      <Badge badgeContent={notificationCount} color="primary" onClick={() => toggleNotifications(true)}>
        <MailIcon />
      </Badge>

      {showNotifications && <Notifications />}

    </div>
  )
}

export default Nav
