import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap'
import { format } from 'date-fns'
import StarRating from '../star_rating'
import StarRatingReview from '../star_rating/review_rating'

const Reviews = ({venue}) => {
  const [showModal, toggleModal] = useState(true)
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    axios.get(`/api/venue_reviews/${venue.id}`).then(r => setReviews(r.data))
  }, [])

  const handleClose = () => {
    toggleModal(false)
  }

  const renderReviews = () => {
    if (!reviews.length) return <p>No reviews.</p>

    return reviews.map(r => {
      return (
        <div key={r.id} className="review__container">

          <div className="reviews__signature">
            by
            <span className="reviews__author"> {r.user.name} </span>
            on
            <span className="reviews__date">
              {' ' + format(new Date(r.created_at), 'MMM d, yyyy')}
            </span>
          </div>

          <div className="reviews__stars">
            <StarRatingReview value={r.stars}/>
          </div>

          <div className="reviews__content">
            {r.content}
          </div>

        </div>
      )
    })
  }

  return (
    <Modal show={showModal} onHide={handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Reviews</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {renderReviews()}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Reviews
