import React, { useEffect, useState } from 'react'
import { Popup } from 'react-map-gl'
import {Carousel} from 'react-bootstrap'
import CloseControl from './CloseControl'
import Body from './Body'

const MapPopup = props => {
  const { selectedVenue, handleMarkerClick, viewport, imagePath } = props

  useEffect(() => {
    const preventDefaultClose =
      document.getElementsByClassName('mapboxgl-popup-close-button')[0] || null
    preventDefaultClose
      ? preventDefaultClose.classList.add('daasly-map-hidden')
      : null
    return () => {
      null
    }
  }, [selectedVenue])

  const renderCarouselImages = venue => {
    if (!venue.images) return null

    return venue.images.map(img => {
      const key = img.url + '__' + venue.id
      return (
        <Carousel.Item className="" key={key}>
          <img src={img.url} className="map-popup__img" />
        </Carousel.Item>
      )
    })
  }

  const renderVenueImages = venue => {
    if (!venue.images.length) return null

    return (
      <Carousel>
        {renderCarouselImages(venue)}
      </Carousel>
    )
  }

  const renderVenuePopup = selectedVenue => {
    return (
      <Popup
        offsetTop={-45} // negative number is equal to height of div, moves pop-up to top of pin
        latitude={Number(selectedVenue.latitude)}
        longitude={Number(selectedVenue.longitude)}
      >
        <CloseControl handleMarkerClick={handleMarkerClick} />

        {renderVenueImages(selectedVenue)}

        <Body {...props} />

      </Popup>
    )
  }
  return <div>{selectedVenue ? renderVenuePopup(selectedVenue) : null}</div>
}

export default MapPopup
