import React, {useState} from 'react'
import axios from 'axios'
import {useForm} from 'react-hook-form'
import {useDropzone} from 'react-dropzone'
import {storage} from '../firebase'
import {offersRoute} from '../utils/routes'

const OffersForm = props => {
  const [imageFiles, setImageFiles] = useState([])
  const [progress, setProgress] = useState(0)
  const { register, handleSubmit } = useForm()

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImageFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const onSubmit = (data, e) => {
    e.preventDefault()
    const {name, street_address, city, state, zip, price_before_discount, price_after_discount, description} = data

    uploadImageToFirebase(imageFiles, name)

    let formData = new FormData()

    if (name) {
      formData.append('name', name)
    }

    if (street_address) {
      formData.append('street_address', street_address)
    }

    if (city) {
      formData.append('city', city)
    }

    if (state) {
      formData.append('state', state)
    }

    if (zip) {
      formData.append('zip', zip)
    }

    if (price_before_discount) {
      formData.append('price_before_discount', price_before_discount)
    }

    if (price_after_discount) {
      formData.append('price_after_discount', price_after_discount)
    }

    if (description) {
      formData.append('description', description)
    }

    axios.post(offersRoute, formData).then(r => {
      document.getElementById("offer-form").reset()
      setImageFiles([])
    })
  }

  const renderImageForm = () => {
    return (
      <div className="u-margin-top-medium">
        <h5 className="admin__header">Upload an image for this offer</h5>
        {progress === 100 ? <h3 className="upload-success">Your offer has been added!</h3> : null}
        {progress > 0 && <progress value={progress} max="100" className="images-progress-bar" />}
        <div className="modal__body picture-dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' Drop an image here, or click to select a file.</p>
        </div>

        <aside className="u-margin-top-small">
          <ul>{thumbs}</ul>
        </aside>
      </div>
    )
  }

  const uploadImageToFirebase = (imageFiles, name) => {
    imageFiles.forEach(imageFile => {
      const imageFileName = imageFile.name.replace(/ +/g, "-")
      const formatName = name.replace(/ +/g, "-")
      const uploadTask = storage.ref(`places2play_public/offers/${formatName}/`).child(imageFileName).put(imageFile)

      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {console.log('Error: ', error)}
      )
    })
  }

  const thumbs = imageFiles.map(file => (
    <div key={file.name} className="dropzone-thumb">
      <img src={file.preview} className="dropzone-preview-img" />
      <span className="u-margin-left-small">{file.name} - {file.size} bytes</span>
    </div>
  ))

  return (
    <div className="admin__container--item">
      <h2>Add a new Offer</h2>

      {renderImageForm()}

      <form id="offer-form" onSubmit={handleSubmit(onSubmit)}>

        <div className="admin__form--group">
          <label htmlFor="name" className="offer__form--label">
            Name
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="name"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="description" className="offer__form--label">
            Description
          </label>

          <input
            type="textarea"
            className="admin__container--input"
            name="description"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="street_address" className="offer__form--label">
            Street Address
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="street_address"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="city" className="offer__form--label">
            City
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="city"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="state" className="offer__form--label">
            State
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="state"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="zip" className="offer__form--label">
            Zip Code
          </label>

          <textarea
            className="admin__container--input"
            name="zip"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="price_before_discount" className="offer__form--label">
            Price before discount
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="price_before_discount"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="price_after_discount" className="offer__form--label">
            Price after discount
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="price_after_discount"
            ref={register}
          />
        </div>

        <div className="admin__btn--container">
          <input type="submit" className="btn__large" value="Add Offer" />
        </div>

      </form>
    </div>
  )
}

export default OffersForm
