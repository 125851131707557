import React from 'react'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'

const SchoolSportsSimplified = props => {

  return (
    <div className="about-us__container">

      <div className="about-us__image--container">
        <img
          className="school-sports-simplified__image"
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/places-2-play-school-sports-simplified.png`}
        />
      </div>

      <div className="about-us__text--container">
        <h2 className="about-us__header">School Sports – Simplified</h2>

        <p className="about-us__text u-margin-top-medium">
          Remember when you were a kid and the only place to find afterschool activity info was posted on a bulletin board? Not much has changed! Most schools still rely on bulletin boards, flyers, or busy parents to search the school website. Enter Places2Play.com...
        </p>
        <p className="about-us__text">
          We provide a streamlined hub of extra-curricular activity information, designed with schools, students, and parents in mind. Places2Play can serve as a host-site for all of your afterschool programs, by providing parents and players with real time info on available clubs, schedules, practice locations, fees, and more.
        </p>
        <p className="about-us__text">
          Places2Play can support your single-location private school, charter schools, and public school districts as a whole. By giving parents an easier way to connect and enroll students in afterschool activities, you're encouraging active, healthy lifestyles for every kid! Reach out to our customer service team for more details.
        </p>

        <a href="mailto:contact@places2play.com?subject=New Message From Website">
          <button className="about-us__btn u-margin-top-medium">
            Contact Us
          </button>
        </a>
      </div>

    </div>
  )
}

export default SchoolSportsSimplified
