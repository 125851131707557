import React, { useState } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Share = (props) => {
  const { selectedVenue } = props
  const [copied, setCopied] = useState(false)
  const [mapAlert, setMapAlert] = useState({
    show: false,
    type: '',
    text: ''
  })

  const getShareLink = (id) => {
    const rootPath = window.location.origin.toString()
    const path = `/sports_venue/${id}/`
    const params = `?source=places-played-popup-share`
    const fullPath = rootPath.concat(path).concat(params)
    return fullPath
  }

  const postAlert = (show, type, text) => {
    const params = { show, type, text }
    setMapAlert({ ...params })
    setTimeout(() => {
      setMapAlert({ show: false })
    }, 1500)
  }
  return (
    <div>
      <OverlayTrigger placement={'top'} overlay={<Tooltip>Share</Tooltip>}>
        <CopyToClipboard
          text={getShareLink(selectedVenue.id)}
          onCopy={() => {
            setCopied(true)
            postAlert(true, 'danger', 'Share link copied!')
          }}
        >
          <span className="daasly-popup-share">
            <i className="fas fa-share-alt daasly-popup-share-icon" />
          </span>
        </CopyToClipboard>
      </OverlayTrigger>
    </div>
  )
}

export default Share
