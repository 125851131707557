import React, { useState, useEffect, useContext } from 'react'
import ReactMapGL, { CanvasOverlay } from 'react-map-gl'
import {
  mapKeyRoute,
  interactionsRoute,
  sportsVenueRoutes,
  sportsVenuePlacesPlayedRoute
} from '../../utils/routes'
import axios from 'axios'
import MyMapController from './MapController'
import Spinner from '../../spinner'
import MapMarker from './Marker'
import MapControls from './MapControls'
import Alerts from './Alerts'
import CustomSidebar from './sidebar/CustomSidebar'

const Map = ({ firebaseUid }) => {
  const [scoreClick, setScoreClick] = useState(false)
  const [mapHeight, setMapHeight] = useState(null)
  const [mapCenter, setMapCenter] = useState()
  const [imagePath, setImagePath] = useState(
    'https://storage.googleapis.com/places2play_public/'
  )
  const [venues, setVenues] = useState()
  const [selectedVenue, setSelectedVenue] = useState()
  const [mapKey, setMapKey] = useState()
  const [mapAlert, setMapAlert] = useState({
    show: false,
    type: '',
    text: ''
  })
  const [viewport, setViewport] = useState()
  const [venueParams, setVenueParams] = useState({
    league: 'adult',
    sport: '',
    favorite: 'No',
    played: 'No',
    page: 0
  })

  const mapController = new MyMapController(setMapCenter)

  useEffect(() => {
    getMapKey()
    mapHeightSize()
    getUserDefaultLocation()
    return () => {}
  }, [])

  useEffect(() => {
    mapCenter && getVenues(venueParams)
    return () => {}
  }, [mapCenter, firebaseUid])

  useEffect(() => {
    getVenues(venueParams)
    return () => {}
  }, [venueParams, firebaseUid])

  useEffect(() => {
    updateSelectedVenue()
    return () => {}
  }, [venues])

  const mapHeightSize = () => {
    const minHeight =
      window.innerHeight /
      parseFloat(getComputedStyle(document.querySelector('body'))['font-size'])
    if (minHeight <= 62) {
      setMapHeight('49rem')
    } else {
      setMapHeight(`calc(100vh - 13rem)`)
    }
  }

  const getMapKey = () => {
    axios.get(mapKeyRoute).then(r => {
      setMapKey(r.data.map_key)
    })
  }

  const getUserDefaultLocation = () => {
    const success = pos => {
      var crd = pos.coords;
      const lat = crd.latitude
      const long = crd.longitude

      setMapCenter({lat,long})
      setViewport({
        ...viewport,
        latitude: lat,
        longitude: long,
        zoom: 12
      })
    }

    const error =  err => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error)
  }

  const getVenues = requestParams => {
    const params = {
      ...requestParams,
      ...mapCenter,
      firebase_uid: firebaseUid
    }
    axios.get(sportsVenuePlacesPlayedRoute, { params }).then(r => {
      setVenues(r.data)
    })
  }

  const updateSelectedVenue = () => {
    const updateVenueInfo = () => {
      const currentSelectedVenue = selectedVenue.id
      const updatedSelectedVenue = venues.filter(r => r.id === selectedVenue.id)
      setSelectedVenue(...updatedSelectedVenue)
    }
    selectedVenue ? updateVenueInfo() : null
  }

  const toggleInteraction = (venueId, type) => {
    const params = {
      venue_id: venueId,
      firebase_uid: firebaseUid,
      type: type
    }
    axios.post(interactionsRoute, { ...params }).then(r => {
      const postStatus = r.data.success
      if (postStatus) {
        getVenues(venueParams)
      } else {
        return error
      }
    })
  }

  const openTab = (tabName, parentTabName) => {
    const xBody = document.getElementsByClassName('daasly-sidebar-tab')
    for (const c of xBody) {
      c.style.display = 'none'
    }
    const xNav = document.getElementsByClassName('daasly-sidebar-nav')
    for (const c of xNav) {
      c.style.borderBottom = '1px black solid'
    }
    document.getElementById(tabName).style.display = 'block'
    document.getElementById(parentTabName).style.borderBottom =
      '1.5px red solid'
  }

  const handleMarkerClick = venue => {
    setSelectedVenue(venue)
    openTab('venue', 'daasly-sidebar-nav-venue')
    venue && insertAnimation(venue.id)
  }

  const insertAnimation = venueId => {
    const markerId = 'marker-' + venueId
    var markerElement = document.getElementById(markerId)

    markerElement.classList.add('fa-spin')

    setTimeout(() => markerElement.classList.remove('fa-spin'), 2050)
  }

  const postAlert = (show, type, text) => {
    const params = {
      show: show,
      type: type,
      text: text
    }
    setMapAlert({ ...params })
    setTimeout(() => {
      setMapAlert({ show: false })
    }, 1500)
  }

  const styles = {
    container: {
      boxShadow: '-1px -1px 10px 10px rgba(0,0,0,0.25)',
      borderRadius: '10px'
    }
  }

  const renderMap = venues => {
    return (
      <>
        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken={mapKey}
          mapStyle='mapbox://styles/mapbox/streets-v11'
          onViewportChange={viewport => {
            mapHeightSize()
            setViewport(viewport)
          }}
          width='95%'
          height={mapHeight}
          controller={mapController}
          style={styles.container}
        >
          <Alerts mapAlert={mapAlert} />
          <MapMarker
            handleMarkerClick={handleMarkerClick}
            toggleInteraction={toggleInteraction}
            firebaseUid={firebaseUid}
            postAlert={postAlert}
            setViewport={setViewport}
            venues={venues}
            selectedVenue={selectedVenue}
            venueParams={venueParams}
            imagePath={imagePath}
          />
          <MapControls viewport={viewport} />
          <CustomSidebar
            setScoreClick={setScoreClick}
            scoreClick={scoreClick}
            firebaseUid={firebaseUid}
            venues={venues}
            selectedVenue={selectedVenue}
            venueParams={venueParams}
            setVenueParams={setVenueParams}
            captureDrag={true}
            captureScroll={true}
          />
        </ReactMapGL>
      </>
    )
  }

  return (
    <div className='places-played-map'>
      <div className='daasly-map-outer'>
        {venues && mapKey && viewport ? (
          renderMap(venues)
        ) : <Spinner />
        }
      </div>
    </div>
  )
}

export default Map
