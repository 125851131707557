import React, { useState, useEffect } from 'react'
import {Carousel} from 'react-bootstrap'
import axios from 'axios'
import Body from '../popup/Body'
import { gamesRoute, interactionsRoute } from '../../../utils/routes'

const Venue = props => {
  const { selectedVenue, firebaseUid } = props
  const [venueScores, setVenueScores] = useState(null)
  const [venueTotalScores, setVenueTotalScores] = useState([])
  const [venueDatesPlayed, setVenueDatesPlayed] = useState([])

  useEffect(() => {
    selectedVenue && getScores()
  }, [selectedVenue])

  const getScores = () => {
    const params = {
      type: selectedVenue.sport_name,
      firebase_uid: firebaseUid,
      venue_id: selectedVenue.id
    }
    axios.get(gamesRoute, { params }).then(r => {
      let totalScores = []
      let dates = []
      setVenueScores(r.data)
      // gets all the scores for a specific venue
      r.data && r.data.forEach(score => totalScores.push(score.total_score))
      setVenueTotalScores(totalScores)
      // gets all the dates for a specific venue
      r.data && r.data.map(date => dates.push(date.created_at))
      setVenueDatesPlayed(dates)
    })
  }

  const mostRecentDate = () => {
    if (venueDatesPlayed.length < 1) return '--'
    let recent = venueDatesPlayed[0]
    venueDatesPlayed.forEach(date => {
      if (recent > date) {
        return recent
      }
      recent = date
    })
    const formattedDate = new Date(recent).toDateString()
    return formattedDate
  }

  const bestScore = venueType => {
    switch (venueType) {
      case 'Golf':
        const bestGolfScore =
          venueTotalScores.length > 0 ? Math.min(...venueTotalScores) : '--'
        return bestGolfScore
      case 'Bowling':
        const bestBowlingScore =
          venueTotalScores.length > 0 ? Math.max(...venueTotalScores) : '--'
        return bestBowlingScore
    }
  }

  const renderVenueImages = selectedVenue => {
    if (!selectedVenue.images.length) return null

    return selectedVenue.images.map(image => {
      const key = image.url + '__' + selectedVenue.id
      return (
        <Carousel.Item key={key}>
          <img src={image.url} className="places_played__venue--img" />
        </Carousel.Item>
      )
    })
  }

  const toggleInteraction = (venueId, type) => {
    const params = {
      venue_id: venueId,
      firebase_uid: firebaseUid,
      type: type
    }
    axios.post(interactionsRoute, { ...params }).then(r => {
      const responseSuccess = r.data.success
      if (responseSuccess && r.data.toggle) {
        alert('Venue was added to your Favorites!')
      } else if (responseSuccess && !r.data.toggle){
        alert('Venue was removed from your Favorites.')
      }
    })
  }

  return (
    <div id='venue' className='daasly-sidebar-tab'>
      {selectedVenue ? (
        <>
          <div className='daasly-sidebar-venue-info'>
            <div className='daasly-sidebar-venue-header'>
              <b>Venue Details</b>
            </div>

            <div className='daasly-popup-cover'>
              <Carousel>
                {renderVenueImages(selectedVenue)}
              </Carousel>
            </div>

            <div>
              <b>{`${selectedVenue.name}`}</b>
            </div>
            <div>{selectedVenue.street_address}</div>
            <div>{`${selectedVenue.city}, ${selectedVenue.state} ${selectedVenue.zip_code}`}</div>
            <div>{selectedVenue.email}</div>
            <div>{selectedVenue.phone}</div>
            <div>{selectedVenue.is_favorite}</div>
            <div>{selectedVenue.is_played}</div>
            <div>
              {' '}
              For More Details{' '}
              <a href={`/sports_venue/${selectedVenue.id}`} target='_blank'>
                Click Here
              </a>
            </div>
          </div>
          <div className='daasly-sidebar-venue-user-info'>
            <div className='daasly-sidebar-venue-games-played-header'>
              <b>Games Played</b>
            </div>
            <table className='table'>
              <tbody>
                <tr>
                  <td>Best Score:</td>
                  <td>{bestScore(selectedVenue.sport_name)}</td>
                </tr>
                <tr>
                  <td>Last Played:</td>
                  <td>{mostRecentDate()}</td>
                </tr>
                <tr>
                  <td>Times Played:</td>
                  <td>{venueDatesPlayed.length}</td>
                </tr>
              </tbody>
            </table>
            {/* <div>Best Score: {bestScore(selectedVenue.sport_name)}</div>
            <div>Last Played: {mostRecentDate()}</div>
            <div>Times Played: {venueDatesPlayed.length}</div> */}
          </div>
          <Body selectedVenue={selectedVenue} firebaseUid={firebaseUid} toggleInteraction={toggleInteraction} />
        </>
      ) : (
        <div>Select venue to view more information.</div>
      )}
    </div>
  )
}

export default Venue
