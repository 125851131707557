import React, {useState, useContext, useRef} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap'
import { AuthContext } from '../auth'
import StarRating from './review_form_star_rating'
import { useForm } from 'react-hook-form'
import { updateRatingRoute, getRatingRoute } from '../utils/routes'

const ReviewForm = ({venue}) => {
  const starRatingRef = useRef(null)
  const [rating, setRating] = useState(null)
  const [showModal, toggleModal] = useState(true)
  const [showSuccessMsg, toggleSuccess] = useState(false)
  const {currentUser} = useContext(AuthContext)
  const { register, handleSubmit } = useForm()

  const handleClose = () => {
    toggleModal(false)
  }

  const onSubmit = ({content}, e) => {
    e.preventDefault();
    const stars = rating
    const review = {
                    reviewable_id: venue.id,
                    reviewable_type: 'SportsVenue',
                    firebase_uid: currentUser.uid,
                    content,
                    stars
                  }

    axios.post(updateRatingRoute, {review}).then(r => {
      toggleModal(false)
      toggleSuccess(true)
      alert('Thanks for your review!')
      window.location.reload()
    })
  }

  const renderFormOrSignInMsg = () => {
    if (!currentUser) return <p>Please sign in to leave a review.</p>

    return (
      <section className="modal__body">
        <form className="review-modal__form" onSubmit={handleSubmit(onSubmit)}>
          <StarRating ref={starRatingRef} setRating={setRating} ratable={venue} ratableType={'SportsVenue'} />

          <textarea name="content" className="review-modal__textarea" ref={register}>
          </textarea>

          <div className="review-modal__btm-row">
            <section className="review-modal__author">
              Logged in as
              <span className="review-modal__author--name">{currentUser.displayName}</span>
            </section>

            <Button variant="primary" type="submit">
              Save Review
            </Button>
          </div>
        </form>

      </section>
    )
  }

  return (
    <>
      {showSuccessMsg && "  Your review has been added!"}
      <Modal show={showModal} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Leave a Review for {venue.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {renderFormOrSignInMsg()}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReviewForm
