export const sportsVenueRoutes = '/api/sports-venues'

export const sportsRoute = '/api/sports'

export const otherSportsRoute = '/api/sports/other'

export const mapKeyRoute = '/api/map_key'

export const currentUserRoute = '/api/current_user'

export const sportsVenueImportRoute = '/api/sports_venues/import'

export const sportsVenuePlacesPlayedRoute = '/api/sports_venues/places_played'

export const interactionsRoute = '/api/interactions'

export const interactionsVenuePlayedRoute = '/api/interactions/update_venue_played'

export const gamesRoute = '/api/games'

export const usersRoute = '/api/users'

export const userSearchRoute = '/api/users/search'

export const userUpdateProfile = '/api/users/update_profile'

export const sendFriendRequestRoute = '/api/send_friend_request'

export const acceptFriendRequestRoute = '/api/accept_friend_request'

export const ignoreFriendRequestRoute = '/api/ignore_friend_request'

export const tipOfTheMonthRoute = '/api/tip_of_the_months'

export const currentTipOfMonthRoute = '/api/tip_of_the_month'

export const isUserAdminRoute = '/api/is_admin'

export const getVideoRoute = '/api/video_link'

export const isAdminRoute = '/api/is_admin'

export const reviewsRoute = '/api/reviews'

export const updateRatingRoute = '/api/update_rating'

export const getRatingRoute = '/api/get_star_rating'

export const suggestEditRoute = '/api/edit_suggestions'

export const jobsRoute = '/api/jobs'

export const offersRoute = '/api/offers'

export const updateOfferRatingRoute = '/api/offers/update_rating'

export const imagesRoute = '/api/images'

export const userImageRoute = '/api/current_user/image'

export const schoolsRoute = '/api/schools'

export const schoolRoute = '/api/school'

export const schoolProgramsRoute = '/api/school_programs'

export const notificationsRoute = '/api/notifications'

export const notificationsCountRoute = '/api/notifications/count'
