import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SelectSport from '../select_sport'
import SelectOtherSports from '../select_sport/other'
import HomePage from '../homepage'
import SelectLocation from '../select_location'
import SearchResults from '../search_results'
import SportsVenueSignUp from '../sign_up/sports_venue'
import PlacesPlayed from '../places_played'
import Profile from '../profile'
import PrivateRoute from '../private_route'
import AdminRoute from '../admin_route'
import Admin from '../admin'
import AboutUsContainer from '../about_us/container'
import Careers from '../careers'
import Offers from '../offers'
import SchoolsShow from '../schools/show'

const AppRouter = props => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />

      <Route
        path="/adults_league_sports"
        render={() => <SelectSport league="adults" />} />

      <Route
        path="/kids_league_sports"
        render={() => <SelectSport league="kids" />} />

      <Route
        path="/:league/other/sports"
        component={SelectOtherSports} />

      <Route
        path="/:league/:sport/location"
        component={SelectLocation} />

      <Route
        path="/:league/:sport/:location/search_results/:page?"
        component={SearchResults} />

      <Route
        path="/sports_venue_signup"
        component={SportsVenueSignUp} />

      <Route
        path="/places_played/:page?"
        component={PlacesPlayed} />

      <Route
        path="/profile"
        component={Profile} />

      <Route
        path="/about"
        component={AboutUsContainer} />

      <Route
        path="/careers"
        component={Careers} />

      <Route
        path="/offers"
        component={Offers} />

      <Route
        path="/school/:school_code"
        component={SchoolsShow} />

      <AdminRoute
        path="/admin"
        component={Admin} />
    </Switch>
  )
}

export default AppRouter
