import React, { Component } from 'react'
import { BaseControl } from 'react-map-gl'
import SideBar from '../SideBar.jsx'

// Custom Component inside react-map-gl, docs below
// https://visgl.github.io/react-map-gl/docs/advanced/custom-components

export default class CustomSidebar extends BaseControl {
  _render() {
    const markerStyle = {
      position: 'absolute',
      background: 'red',
      right: 0,
      top: 0,
      width: '100%'
    }
    const {
      firebaseUid,
      venues,
      selectedVenue,
      venueParams,
      setVenueParams,
      setScoreClick,
      scoreClick
    } = this.props

    return (
      <div style={markerStyle} ref={this._containerRef}>
        <SideBar
          setScoreClick={setScoreClick}
          scoreClick={scoreClick}
          firebaseUid={firebaseUid}
          venues={venues}
          selectedVenue={selectedVenue}
          venueParams={venueParams}
          setVenueParams={setVenueParams}
        />
      </div>
    )
  }
}
