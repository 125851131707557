import axios from 'axios'

export const GOOGLE_CLOUD_STORAGE_PUBLIC_PATH = "https://storage.googleapis.com/places2play_public"

export const axiosHelper = () => {
  const csrfToken = document.querySelector('[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
}

export const capitalize = string => string[0].toUpperCase() + string.slice(1)

export const leagueOptions = [
    { label: 'Adult League', value: 'adults' },
    { label: 'Kids League', value: 'kids'},
    { label: 'Both', value: 'kids_and_adults'}
  ]

// TODO change all insta videos to YT; insta changed video embed rules
export const sportsVideos = [
  {sport: 'Boxing', link: 'https://www.youtube.com/watch?v=JRbSJoT15yM', thumbnail: 'Boxing.png'},
  {sport: 'Dance', link: 'https://www.youtube.com/watch?v=ymigWt5TOV8', thumbnail: 'Dance.png'},
  {sport: 'Rowing', link: 'https://www.instagram.com/p/B9Xgduhn63X/?utm_source=ig_web_copy_link', thumbnail: 'Rowing.png'},
  {sport: 'Swimming', link: 'https://www.instagram.com/tv/CBICQ3MABWH/?utm_source=ig_web_copy_link', thumbnail: 'Swimming.png'},
  {sport: 'Sailing', link: 'https://www.instagram.com/p/CBG6CuNAw2J/?utm_source=ig_web_copy_link', thumbnail: 'Sailing.png'},
  {sport: 'Gym', link: 'https://www.instagram.com/p/CBsdybqAfDd/?utm_source=ig_web_copy_link', thumbnail: 'Gym.png'},
  {sport: 'Cycling', link: 'https://www.instagram.com/tv/CDT96T4nEKo/?utm_source=ig_web_copy_link', thumbnail: 'Cycling.png'},
  {sport: 'Baseball', link: 'https://www.instagram.com/p/CB937q9p5ca/?utm_source=ig_web_copy_link', thumbnail: 'Baseball.png'},
  {sport: 'Poker', link: 'https://www.youtube.com/watch?v=Y_dVZYyBxjg', thumbnail: 'Poker.png'},
  {sport: 'Basketball', link: 'https://www.instagram.com/tv/CDT6WfYAqMi/?utm_source=ig_web_copy_link', thumbnail: 'Basketball.png'},
  {sport: 'Tennis', link: 'https://www.instagram.com/p/CDZNLWfAEAp/?utm_source=ig_web_copy_link', thumbnail: 'Tennis.png'},
  {sport: 'Soccer', link: 'https://www.instagram.com/p/CDYb83qlVQD/?utm_source=ig_web_copy_link', thumbnail: 'Soccer.png'},
  {sport: 'Hockey', link: 'https://www.instagram.com/p/B7O8wt3AMHe/?utm_source=ig_web_copy_link', thumbnail: 'Hockey.png'},
  {sport: 'Water Sports', link: 'https://www.instagram.com/tv/CApRVLOnA3a/?utm_source=ig_web_copy_link', thumbnail: 'Water Sports.png'},
  {sport: 'Yoga', link: 'https://www.instagram.com/p/CDZtIe8JOlX/?utm_source=ig_web_copy_link', thumbnail: 'Yoga.png'},
  {sport: 'Gymnastics', link: 'https://www.instagram.com/p/CDZtIe8JOlX/?utm_source=ig_web_copy_link', thumbnail: 'Gymnastics.png'},
  {sport: 'Skiing', link: 'https://www.youtube.com/watch?v=jh1Lj9eGjjU', thumbnail: 'Skiing.png'},
  {sport: 'Running', link: 'https://www.youtube.com/watch?v=q_glywo3P68', thumbnail: 'Running.png'},
  {sport: 'Rugby', link: 'https://www.instagram.com/p/B8tt2_BgKrF/?utm_source=ig_web_copy_link', thumbnail: 'Rugby.png'},
  {sport: 'Paintball', link: 'https://www.instagram.com/p/Bm_7lDOBuRV/?utm_source=ig_web_copy_link', thumbnail: 'Paintball.png'},
  {sport: 'Softball', link: 'https://www.instagram.com/p/CDUnkEFg-Xq/?utm_source=ig_web_copy_link', thumbnail: 'Softball.png'},
  {sport: 'Volleyball', link: 'https://www.instagram.com/p/B3xw-GalFw1/?utm_source=ig_web_copy_link', thumbnail: 'Volleyball.png'},
  {sport: 'Football', link: 'https://www.instagram.com/p/CAEZlRjBJ_Q/?utm_source=ig_web_copy_link', thumbnail: 'Football.png'},
  {sport: 'Rock Climbing', link: 'https://www.instagram.com/p/Bf_cH3gDtXT/?utm_source=ig_web_copy_link', thumbnail: 'Rock Climbing.png'},
  {sport: 'Polo', link: 'https://www.instagram.com/p/B_SkSGAJYsX/?utm_source=ig_web_copy_link', thumbnail: 'Polo.png'},
  {sport: 'Fishing', link: 'https://www.instagram.com/p/BvZ17RlA6cA/?utm_source=ig_web_copy_link', thumbnail: 'Fishing.png'},
  {sport: 'Martial Arts', link: 'https://www.instagram.com/p/BZTZYvTB43w/?utm_source=ig_web_copy_link', thumbnail: 'Martial Arts.png'},
  {sport: 'Camping', link: 'https://www.youtube.com/watch?v=xUqTgNJgWUs', thumbnail: 'Camping.png'},
  {sport: 'Golf', link: 'https://storage.googleapis.com/places2play_public/tip-of-the-month/golf_1.mov', thumbnail: 'Golf.png'},
  {sport: 'Bowling', link: 'https://www.youtube.com/watch?v=B1fyuubFtk0', thumbnail: 'Bowling.png'},
  {sport: 'Dodgeball', link: 'https://www.youtube.com/watch?v=xIflCT8Kxhg', thumbnail: 'Dodgeball.png'},
  {sport: 'Music', link: 'https://www.youtube.com/watch?v=HNSaXAe8tyg', thumbnail: 'Music.png'},
  {sport: 'Playground', link: 'https://www.youtube.com/watch?v=HF5zg-SmKEE', thumbnail: 'Playground.png'},
  {sport: 'Other', link: 'https://www.youtube.com/watch?v=f-QqJvi_cek', thumbnail: 'Other.png'},
]
