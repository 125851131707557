import React from 'react'

const BowlingForm = ({ setFormData, formData, postScore }) => {
  return (
    <form id="scoreForm">
      <div className="form-group">
        <label htmlFor="bowlingScoreTotal" className="daasly-sidebar-score-label">
          Total Score
        </label>
        <input
          type="number"
          className="form-control"
          id="bowlingScoreTotal"
          onChange={(e) => setFormData({ ...formData, total_score: e.target.value })}
          value={formData ? formData.total_score : ''}
        />
        <small id="bowlingScoreTotalId" className="form-text text-muted">
          Enter your total score for each round.
        </small>
        <button
          type="button"
          className="btn btn-primary float-right daasly-sidebar-score-button"
          onClick={postScore}
        >
          Submit Score
        </button>
      </div>
    </form>
  )
}

export default BowlingForm
