import React, { useEffect, useState } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Favorite from './Favorite'
import Played from './Played'
import Share from './Share'
import { gamesRoute } from '../../../utils/routes.js'
import axios from 'axios'

const Body = props => {
  const [venueScores, setVenueScores] = useState(null)
  const [venueTotalScores, setVenueTotalScores] = useState([])
  const { selectedVenue, firebaseUid } = props

  useEffect(() => {
    selectedVenue && getScores()
  }, [selectedVenue])

  const getScores = () => {
    const params = {
      type: selectedVenue.sport_name,
      firebase_uid: firebaseUid,
      venue_id: selectedVenue.id
    }
    axios.get(gamesRoute, { params }).then(r => {
      let totalScores = []
      setVenueScores(r.data)
      // gets all the scores for a specific venue
      r.data && r.data.forEach(score => totalScores.push(score.total_score))
      setVenueTotalScores(totalScores)
    })
  }

  const bestScore = venueType => {
    switch (venueType) {
      case 'Golf':
        const bestGolfScore =
          venueTotalScores.length > 0 ? Math.min(...venueTotalScores) : '--'
        return bestGolfScore
      case 'Bowling':
        const bestBowlingScore =
          venueTotalScores.length > 0 ? Math.max(...venueTotalScores) : '--'
        return bestBowlingScore
    }
  }

  const renderName = name => {
    const displayName =
      name.length > 18 ? (
        <OverlayTrigger
          placement={'top'}
          overlay={<Tooltip>{selectedVenue.name}</Tooltip>}
        >
          <div>{`${name.substring(0, 18)}...`}</div>
        </OverlayTrigger>
      ) : (
        <div>{name}</div>
      )
    return displayName
  }

  const renderWebsite = website => {
    return (
      <a href={website} className='daasly-popup-link' target='blank'>
        {website
          .replace('http://', '')
          .replace('https://', '')
          .replace('www.', '')}
      </a>
    )
  }

  return (
    <>
      <div className='daasly-popup-header'>
        <div>
          <Played
            firebaseUid={firebaseUid}
            selectedVenue={selectedVenue}
            toggleInteraction={props.toggleInteraction}
            postAlert={props.postAlert}
          />
          <Favorite
            firebaseUid={firebaseUid}
            selectedVenue={selectedVenue}
            toggleInteraction={props.toggleInteraction}
            postAlert={props.postAlert}
          />
          <Share selectedVenue={selectedVenue} postAlert={props.postAlert} />
        </div>
      </div>

      <div className='daasly-popup-body-outer'>
        <div className='daasly-popup-body-inner'>
          <div className='daasly-popup-body-info'>
            <div className='daasly-popup-body-info-phone'>
              <span>
                <b>Phone</b>
              </span>
              {selectedVenue.phone ? (
                <a
                  href={`tel:${selectedVenue.phone}`}
                  className='daasly-popup-link'
                >
                  {selectedVenue.phone}
                </a>
              ) : (
                <div>Not Available</div>
              )}
            </div>
            <div className='daasly-popup-body-info-website'>
              <span>
                <b>Website</b>
              </span>
              {selectedVenue.website ? (
                renderWebsite(selectedVenue.website)
              ) : (
                <div>Not Available</div>
              )}
            </div>
          </div>
          {venueScores && venueTotalScores ? (
            <div className="places_played_map__best_score--container">
              <div className="places_played_map__best_score--border">
                {bestScore(selectedVenue.sport_name)}
              </div>

              <span className="places_played_map__best_score--label">
                Best Score
              </span>
            </div>
          ) : (
            <div className="places_played_map__best_score--container">
              <span> -- </span>
              <span>Best Score</span>
            </div>
          )}
        </div>
      </div>

      <div className='daasly-popup-footer'></div>
    </>
  )
}

export default Body
