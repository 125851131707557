import React, {useState, useEffect} from 'react'
import HomePage from '../homepage'
import {Route} from 'react-router-dom'
import axios from 'axios'
import {axiosHelper} from '../utils'
import {useAuthContext} from '../auth'
import {isAdminRoute} from '../utils/routes'

const AdminRoute = ({component, ...options}) => {
  const [admin, setAdmin] = useState(false)
  const {currentUser} = useAuthContext()

  useEffect(() => {
    const isAdmin = email => {
      axiosHelper()
      axios.post(isAdminRoute, {email}).then(r => setAdmin(r.data))
    }

    if (currentUser) isAdmin(currentUser.email)
  }, [currentUser])

  const finalComponent = currentUser && admin ? component : HomePage

  return <Route {...options} component={finalComponent} />
}

export default AdminRoute
