import React, {useState} from 'react'
import axios from 'axios'
import {useForm} from 'react-hook-form'
import {schoolsRoute} from '../utils/routes'
import SchoolProgramForm from './school_program_form'

const SchoolForm = props => {
  const { register, handleSubmit } = useForm()
  const [showProgramForm, toggleProgramForm] = useState()

  const onSubmit = (params, e) => {
    e.preventDefault()

    axios.post(schoolsRoute, params).then(r => {
      if (r.data.success) {
        let schoolCode = r.data.school_code
        alert(`You successfully added ${params.name} with school code ${schoolCode}!`)
      }

      document.getElementById("school-form").reset()
    })
  }

  return (
    <div className="admin__container--item">
      <h2>Add a new School</h2>

      <form id="school-form" onSubmit={handleSubmit(onSubmit)}>

        <div className="admin__form--group">
          <label htmlFor="name" className="job__form--label">
            Name
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="name"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="street_address" className="job__form--label">
            Street Address
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="street_address"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="city" className="job__form--label">
            City
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="city"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="state" className="job__form--label">
            State
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="state"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="zip_code" className="job__form--label">
            Zip Code
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="zip_code"
            ref={register}
          />
        </div>

        <div className="admin__btn--container">
          <input type="submit" className="btn__large" value="Add School" />
        </div>

      </form>
    </div>
  )
}

export default SchoolForm
