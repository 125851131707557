import React from 'react'
import Nav from '../nav'
import Footer from '../footer'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'

const AboutUs = props => {

  return (
    <div className="about-us__container">

      <div className="about-us__text--container">
        <h2 className="about-us__header">About Us</h2>

        <p className="about-us__text u-margin-top-medium">
          Places2Play.com is known for helping local intramural players and children connect with leagues and locations in their area. Places2Play.com is a South Florida company based in Miami Beach. We have become and remain the fastest growing sports search engine in America, and pride ourselves in being known as the utmost, user-friendly connection for athletes, everywhere. By rapidly expanding our data and reach, we aim to provide our services to as many people as possible, as a conduit to healthy and memorable experiences for all.
        </p>

        <a href="mailto:contact@places2play.com?subject=New Message From Website">
          <button className="about-us__btn u-margin-top-medium">
            Contact Us
          </button>
        </a>
      </div>

      <div className="about-us__image--container">
        <img
          className="about-us__left--image"
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/places-2-play-bobby.png`}
        />
        <img
          className="about-us__right--image"
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/places-2-play-robert.png`}
        />
      </div>

    </div>
  )
}

export default AboutUs
