import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {jobsRoute} from '../utils/routes'
import {storage} from '../firebase'
import {Carousel} from 'react-bootstrap'
import companyIcon from '../images/miscellaneous_icons/company-icon.png'
import locationIcon from '../images/miscellaneous_icons/location-icon.png'
import lightbulbIcon from '../images/miscellaneous_icons/lightbulb-icon.png'
import manIcon from '../images/miscellaneous_icons/man-icon.png'
import clockIcon from '../images/miscellaneous_icons/clock-icon.jpg'
import Nav from '../nav'
import Footer from '../footer'

const Careers = props => {
  const [jobs, setJobs] = useState([])
  const [imageURLs, setImageURLs] = useState({})
  const [selectedJob, setSelectedJob] = useState()

  useEffect(() => {
    const getJobs = () => {
      axios.get(jobsRoute).then(r => setJobs(r.data))
    }
    getJobs()
  }, [])

  useEffect(() => {
    getImagesFromFirebase()
  }, [jobs])

  const getImagesFromFirebase = () => {
    if (!jobs.length) return null

    return jobs.map(job => {
      var listRef = storage.ref().child(`places2play_public/jobs/${job.image_folder}/`)

      var jobURLs = []
      listRef.listAll().then(res => {
        res.items.forEach(imageRef => {
          imageRef.getDownloadURL().then(url => {
            jobURLs.push(url)
            setImageURLs(prevState => ({...prevState, [job.id]: jobURLs}))
          })
        })
      }).catch(error => console.log('Error: ', error))
    })
  }

  const renderJobs = () => {
    return jobs.map(job => {
      return (
        <tr className="job__container" key={job.id} onClick={() => setSelectedJob(job)}>
          {renderJobDetails(job)}
        </tr>
      )
    })
  }

  const renderJobDetails = (job, featured = false) => {
    return (
      <>
        <td>
          <h2 className="job__title">{job.title}</h2>
        </td>

        <td className="job__detail">
          {job.company}
        </td>

        <td className="job__detail">
          {job.location}
        </td>
      </>
    )
  }

  const renderJobImages = (job, featured = false) => {
    if (!imageURLs[job.id]) return null

    return imageURLs[job.id].map(url => {
      return (
        <Carousel.Item className="" key={url}>
          <img src={url} className={`job__image ${featured ? 'featured__img' : ''}`} />
        </Carousel.Item>
      )
    })
  }

  const renderSelectedJob = () => {
    if (!selectedJob) return null
    return (
      <div className="job__selected--outer-container">
        <div className="job__selected--container">
          <Carousel>
            {renderJobImages(selectedJob, true)}
          </Carousel>

          {renderJobDetails(selectedJob, true)}
        </div>

        <div className="job__company--details">
          <div className="job__btm--row--jobs">
            <h3>Jobs</h3>
            <span className="job__company--details--item">{selectedJob.number_of_applicants || 0} applicants</span>
            <span className="job__company--details--item">50 employees needed</span>
            <span className="job__company--details--item">{selectedJob.requirements}</span>
          </div>

          <div className="job__btm--row--company">
            <h3>Company</h3>
            <span className="job__company--details--item">{selectedJob.benefit_1}</span>
            <span className="job__company--details--item">{selectedJob.benefit_2}</span>
            <span className="job__company--details--item">{selectedJob.salary}</span>
          </div>
        </div>

        <div className="job__company--description">
          <h3>Description</h3>
          <div className="job__company--description--item">{selectedJob.description}</div>
        </div>

      </div>
    )
  }

  return (
    <>
      <div className="careers__container">
        <div className="careers__header--area">
          <Nav />
          <h1 className="header">Careers</h1>
        </div>

        <div className="careers__jobs--container">
          <h1 className="careers__header">Join the Company</h1>
          <h1 className="job__header">{jobs.length || 0} Jobs Found</h1>

          <div className="careers__table--container">
            <table className="careers__table">
              <thead>
                <td>Job</td>
                <td>Category</td>
                <td>Location</td>
              </thead>

              <tbody>
                {renderJobs()}
              </tbody>
            </table>
          </div>

        </div>

        {renderSelectedJob()}
      </div>

      <Footer />
    </>
  )
}

export default Careers
