import React, {useState, useEffect, useContext} from 'react'
import {imagesRoute} from '../utils/routes'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap'
import {AuthContext} from '../auth'
import {useDropzone} from 'react-dropzone'
import {storage} from '../firebase'

const PictureForm = ({venue, images, setImages}) => {
  const [showModal, toggleModal] = useState(true)
  const [showSuccessMsg, toggleSuccess] = useState(false)
  const [files, setFiles] = useState([])
  const [progress, setProgress] = useState(0)
  const {currentUser} = useContext(AuthContext)

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const thumbs = files.map(file => (
    <div key={file.name} className="dropzone-thumb">
      <img src={file.preview} className="dropzone-preview-img" />
      <span className="u-margin-left-small">{file.name} - {file.size} bytes</span>
    </div>
  ))

  useEffect(() => () => {
    // Revoke data URIs to avoid memory leaks. From react-dropzone.js.org
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  const handleClose = () => {
    toggleModal(false)
  }

  const handleSave = () => {
    files.forEach(file => {
      const fileName = file.name.replace(/ +/g, "-")
      const uploadTask = storage.ref(`places2play_public/venues/${venue.id}/`).child(fileName).put(file)

      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {console.log('Error: ', error)},
        () => {
          storage
            .ref(`places2play_public/venues/${venue.id}/`)
            .child(fileName)
            .getDownloadURL()
            .then(url => {
              const params = {url, sports_venue_id: venue.id}
              axios.post(imagesRoute, params)
                .then(r => {
                  setImages([...images, url])
                })
            })
        }
      )
    })
  }

  const renderFormOrSignInMsg = () => {
    if (!currentUser) return <p>Please sign in to upload pictures.</p>

    return (
      <>
        {progress === 100 ? <h3 className="upload-success">Your images have been uploaded!</h3> : null}
        {progress > 0 && <progress value={progress} max="100" className="images-progress-bar" />}
        <div className="modal__body picture-dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' Drop some images here, or click to select files (hold CONTROL key to select multiple)</p>
        </div>

        <aside className="u-margin-top-small">
          <h4>Images</h4>
          <ul>{thumbs}</ul>
        </aside>

        <hr className="u-margin-top-medium"/>

        <div className="review-modal__btm-row">
          <section className="review-modal__author">
            Logged in as
            <span className="review-modal__author--name">{currentUser.displayName}</span>
          </section>
        </div>
      </>
    )
  }

  const disableIfUploading = progress > 0 ? "disabled" : ""

  return (
    <>
      {showSuccessMsg && "  Your pictures have been added! Thanks!"}
      <Modal show={showModal} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Upload Images for {venue.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {renderFormOrSignInMsg()}
        </Modal.Body>

        <Modal.Footer>
          <Button variant={`primary ${disableIfUploading}`} onClick={handleSave}>
            Upload Images
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PictureForm
