import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useForm} from 'react-hook-form'
import {schoolsRoute, schoolProgramsRoute} from '../utils/routes'
import Select from 'react-select'
import {useDropzone} from 'react-dropzone'
import {storage} from '../firebase'

const SchoolProgramForm = props => {
  const [imageFiles, setImageFiles] = useState([])
  const { register, handleSubmit } = useForm()
  const [ school, setSchool ] = useState()
  const [progress, setProgress] = useState(0)
  const [ schoolOptions, setSchoolOptions ] = useState([])

  useEffect(() => {
    const getSchools = () => {
      axios.get(schoolsRoute).then(r => createSchoolOptions(r.data))
    }
    getSchools()
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImageFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const createSchoolOptions = schoolObjs => {
    // Format response objects into value/label objs for react-select
    setSchoolOptions(schoolObjs.map(obj => {
      return {
        value: obj.id,
        label: `${obj.name} - ${obj.school_code} - ${obj.street_address}`
      }
    }))
  }

  const uploadImageToFirebase = (imageFiles, programName, schoolId) => {
    imageFiles.forEach(imageFile => {
      const imageFileName = imageFile.name.replace(/ +/g, "-")
      const uploadTask = storage.ref(`places2play_public/after-school-programs/${schoolId}/${programName}`).child(imageFileName).put(imageFile)

      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {console.log('Error: ', error)}
      )
    })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    let programName = data.name
    let params = data
    params.school_id = school.value

    uploadImageToFirebase(imageFiles, programName, params.school_id)

    axios.post(schoolProgramsRoute, params).then(r => {
      if (r.data.success) {
        document.getElementById("school-program-form").reset()
        alert(`You successfully added ${programName} to ${school.label}!`)
        setImageFiles([])
      }
    })
  }

  const thumbs = imageFiles.map(file => (
    <div key={file.name} className="dropzone-thumb">
      <img src={file.preview} className="dropzone-preview-img" />
      <span className="u-margin-left-small">{file.name} - {file.size} bytes</span>
    </div>
  ))

  const renderImageForm = () => {
    return (
      <div className="u-margin-top-medium">
        <h5 className="admin__header">Upload an image for this Program</h5>
        {progress === 100 ? <h3 className="upload-success">Your pictures have been uploaded!</h3> : null}
        {progress > 0 && <progress value={progress} max="100" className="images-progress-bar" />}
        <div className="modal__body picture-dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' Drop an image here, or click to select a file.</p>
        </div>

        <aside className="u-margin-top-small">
          <ul>{thumbs}</ul>
        </aside>
      </div>
    )
  }

  return (
    <div className="admin__container--item">
      <h2>Add a new School Program</h2>

      {renderImageForm()}

      <form id="school-program-form" onSubmit={handleSubmit(onSubmit)}>

        <div className="admin__form--group">
          <label htmlFor="school_id" className="job__form--label">
            School
          </label>

          <Select
            options={schoolOptions}
            className="admin__school-select u-margin-top-small"
            ref={register}
            onChange={school => setSchool(school)}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="name" className="job__form--label">
            Name
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="name"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="day_offered" className="job__form--label">
            Day Offered
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="day_offered"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="time_offered" className="job__form--label">
            Time Offered
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="time_offered"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="contact_email" className="job__form--label">
            Contact Email
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="contact_email"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="contact_number" className="job__form--label">
            Contact Number
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="contact_number"
            ref={register}
          />
        </div>


        <div className="admin__form--group">
          <label htmlFor="organizer" className="job__form--label">
            Organizer
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="organizer"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="boys_league" className="job__form--label">
            Boys League
          </label>

          <input
            id="boys_league"
            type="checkbox"
            className="admin__container--input"
            name="boys_league"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="girls_league" className="job__form--label">
            Girls League
          </label>

          <input
            id="girls_league"
            type="checkbox"
            className="admin__container--input"
            name="girls_league"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="age_range" className="job__form--label">
            Age Range
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="age_range"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="monthly_fee" className="job__form--label">
            Monthly Fee
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="monthly_fee"
            ref={register}
          />
        </div>

        <div className="admin__form--group">
          <label htmlFor="registration_link" className="job__form--label">
            Registration Link
          </label>

          <input
            type="text"
            className="admin__container--input"
            name="registration_link"
            ref={register}
          />
        </div>

        <div className="admin__btn--container">
          <input type="submit" className="btn__large" value="Add After School Program" />
        </div>

      </form>

    </div>
  )
}

export default SchoolProgramForm
