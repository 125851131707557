import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { gamesRoute } from '../../../utils/routes'
import GolfForm from './forms/GolfForm'
import GolfList from './lists/GolfList'
import BowlingForm from './forms/BowlingForm'
import BowlingList from './lists/BowlingList'

const Scores = props => {
  const { firebaseUid, selectedVenue } = props
  const [formData, setFormData] = useState(null)
  const [venueScores, setVenueScores] = useState()

  useEffect(() => {
    setFormData(null)
    selectedVenue && getScores()
  }, [selectedVenue])


  const getScores = () => {
    const params = {
      type: selectedVenue.sport_name,
      firebase_uid: firebaseUid,
      venue_id: selectedVenue.id
    }
    axios.get(gamesRoute, { params }).then(r => {
      setVenueScores(r.data)
    })
  }

  const postScore = e => {
    e.preventDefault()
    const params = {
      ...formData,
      type: selectedVenue.sport_name,
      firebase_uid: firebaseUid,
      venue_id: selectedVenue.id
    }

    axios.post(gamesRoute, { ...params }).then(r => {
      if (r.data.success) alert('Your score was added!')
      setFormData(null)
      getScores()
    })
  }

  const renderForm = sportName => {
    switch (sportName) {
      case 'Golf': {
        return (
          <GolfForm
            setFormData={setFormData}
            formData={formData}
            postScore={postScore}
          />
        )
        break
      }
      case 'Bowling': {
        return (
          <BowlingForm
            setFormData={setFormData}
            formData={formData}
            postScore={postScore}
          />
        )
        break
      }
      default: {
        return <div>Coming Soon!</div>
        break
      }
    }
  }

  const renderList = sportName => {
    switch (sportName) {
      case 'Golf': {
        return <GolfList venueScores={venueScores} />
        break
      }
      case 'Bowling': {
        return <BowlingList venueScores={venueScores} />
        break
      }
      default: {
        return <div>Coming Soon!</div>
        break
      }
    }
  }

  return (
    <div id='scores' className='daasly-sidebar-tab'>
      {selectedVenue ? (
        <div className='daasly-side-bar-score'>
          <div className='daasly-sidebar-form-title'>
            <b>Add New Score</b>
          </div>
          <div className='daasly-sidebar-form'>
            {renderForm(selectedVenue.sport_name)}
          </div>
          <div className='daasly-sidebar-list-title'>
            <b>Review Existing Scores</b>
          </div>
          <div className='daasly-sidebar-list'>
            {renderList(selectedVenue.sport_name)}
          </div>
        </div>
      ) : (
        <div>Select venue to enter score.</div>
      )}
    </div>
  )
}

export default Scores
