import React, {useState, useEffect} from 'react'
import {getDistance, convertDistance} from 'geolib'
import axios from 'axios'
import { format } from 'date-fns'
import {Carousel} from 'react-bootstrap'
import Spinner from '../spinner'
import {useAuthContext} from '../auth'
import {axiosHelper} from '../utils'
import {isAdminRoute, updateRatingRoute, sportsVenueRoutes} from '../utils/routes'
import SportsVenueModal from '../sports_venue/modal'
import SportsVenueForm from '../sports_venue/form'
import StarRating from './star_rating'
import ReviewForm from './review_form'
import Reviews from './reviews'
import PictureForm from './picture_form'
import SuggestEdit from './suggest_edit'

const SearchResultHit = ({venue, setSelectedVenue, userLocation}) => {
  const [showReviews, toggleReviews] = useState(false)
  const [showReviewForm, toggleReviewForm] = useState(false)
  const [showAdminForm, toggleAdminForm] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [showSuggestEdit, toggleSuggestEdit] = useState(false)
  const [showPictureForm, togglePictureForm] = useState(false)
  const {currentUser} = useAuthContext()
  const [images, setImages] = useState(venue?.images.map(img => img.url))

  useEffect(() => {
    const isAdmin = email => {
      axiosHelper()
      axios.post(isAdminRoute, {email}).then(r => setAdmin(r.data))
    }

    if (currentUser) isAdmin(currentUser.email)
  }, [currentUser])

  const deleteVenue = () => {
    var shouldDelete = confirm('Are you sure you want to delete this venue?')

    if (shouldDelete) {
      axios.delete(sportsVenueRoutes + `/${venue.id}`).then(r => {
        r.data.success && alert('Venue successfully deleted!')
        window.location.reload()
      })
    }
  }

  const renderEditButton = () => {
    return (
      <>
        <button
          onClick={() => toggleAdminForm(!showAdminForm)}
          className="search-result__card--btn-edit">
          Edit
        </button>

        <button
          onClick={() => deleteVenue()}
          className="search-result__card--btn-edit">
          Delete
        </button>

        <span className="search-result__card--edit-text">
          Admin only
        </span>
      </>
    )
  }

  const addressFirstLine = () => {
    const {street_address} = venue
    return `${street_address}`
  }

  const addressSecondLine = () => {
    const {city, state, zip_code} = venue
    return `${city}, ${state}, ${zip_code}`
  }

  const setRating = (stars, venue) => {
    const review = {
      stars,
      firebase_uid: currentUser.uid,
      reviewable_id: venue.id,
      reviewable_type: 'SportsVenue'// required format for server to find model type
    }
    axios.post(updateRatingRoute, {review}).then(r => {
      r.data.success && alert(`Your rating for ${venue.name} was updated to ${stars} stars!`)
    })
  }

  const renderVenueImages = venue => {
    if (!images) return null

    return images.map(url => {
      const key = url + '__' + venue.id
      return (
        <Carousel.Item className="" key={key}>
          <img src={url} className="search-result__card--image" />
        </Carousel.Item>
      )
    })
  }

  const formatPhone = phone => {
    if (!phone) return 'Phone Number Not Available'

    var formattedPhone = phone.replace('+', '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '')

    if (formattedPhone.length === 11) {
      return <span>{`(${formattedPhone.slice(0, 4)}) ${formattedPhone.slice(4, 7)}-${formattedPhone.slice(7)}`}</span>
    } else if (formattedPhone.length === 10) {
      return <span>{`(${formattedPhone.slice(0, 3)}) ${formattedPhone.slice(3, 6)}-${formattedPhone.slice(6)}`}</span>
    }
  }

  const userReportDate = () => {
    if (venue.sport_name !== 'Golf') return null

    const date = format(new Date(venue.user_report_last_punch_date), 'MMM d, yyyy')
    return <span className='u-margin-left-small'>{`User Report Last Punch: ${date}`}</span>
  }

  const renderDistance = venue => {
    const {latitude, longitude} = venue
    const venueCoords = {latitude, longitude}
    if (!userLocation) return <Spinner />
    const distanceInMiles = Math.round(convertDistance(getDistance(userLocation, venueCoords), 'mi'))
    return `${distanceInMiles} miles away`
  }

  return (
    <div key={venue.id} className="search-result__card" onClick={() => setSelectedVenue(venue)}>
      {showAdminForm && <SportsVenueForm id={venue.id} league={venue.league} />}
      {showReviews && <Reviews venue={venue} />}
      {showSuggestEdit && <SuggestEdit venue={venue} />}

      <div className="search-result__card--container">
        <Carousel>
          {renderVenueImages(venue)}
        </Carousel>

        <div className="search-result__card--text-container">
          <div className="search-result__card--header">
            {venue.name}
          </div>

          <div className="search-result__card--distance">
            {renderDistance(venue)}
          </div>

          <div className="search-result__card--rating">
            <StarRating setRating={setRating} ratable={venue} ratableType={'SportsVenue'} />

            <span className="search-result__card--review-average">
              {venue.review_average} Star Average
            </span>

            <span className="search-result__card--review-count">
              {venue.review_count} Reviews
            </span>
          </div>

          <div className="search-result__card--review-container">
            <span className="search-result__card--review" onClick={() => toggleReviewForm(!showReviewForm)}>
              Leave a review
            </span>
            {showReviewForm && <ReviewForm venue={venue}/>}

            <span className="search-result__card--add-picture" onClick={() => togglePictureForm(!showPictureForm)}>
              Add Pictures
            </span>
            {showPictureForm && <PictureForm venue={venue} images={images} setImages={setImages}/>}
          </div>

          <div className="search-result__card--snippet">
            <div className="search-result__card--address">
              {addressFirstLine()}
            </div>

            <div>
              {addressSecondLine()}
            </div>

            <div>
              {formatPhone(venue.phone)}
              {userReportDate()}
            </div>

            {venue.website &&
              <a href={`${venue.website}`} target="_blank">Website</a>
            }
          </div>

          <div className="search-result__card--edit-container">
            <button
              className="search-result__card--btn-reviews"
              onClick={() => toggleReviews(!showReviews)}>
              Reviews
            </button>

            <span onClick={() => toggleSuggestEdit(!showSuggestEdit)} className="search-result__card--suggest-edit">
              Suggest an Edit
            </span>

            {currentUser && admin && renderEditButton()}
          </div>

        </div>
      </div>
    </div>
  )
}

export default SearchResultHit
