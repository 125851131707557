import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'
import {notificationsRoute, acceptFriendRequestRoute, ignoreFriendRequestRoute} from '../utils/routes'
import { AuthContext } from '../auth'

const Notifications = () => {
  const {currentUser} = useContext(AuthContext)
  const [notifications, setNotifications] = useState()
  const [showFriendRequestAccepted, toggleFriendRequestAccepted] = useState()
  const [showFriendRequestIgnored, toggleFriendRequestIgnored] = useState()

  useEffect(() => {
    const getNotifications = () => {
      if (currentUser) {
        const params = {firebase_uid: currentUser.uid}
        try {
          axios.get(notificationsRoute, {params})
            .then(r => r.data.success && setNotifications(r.data.notifications))
        } catch (e) {
          if (axios.isCancel(e)) {
            console.log('caught cancel error from axios request')
          } else {
            throw e
          }
        }
      }
    }

    getNotifications()
  }, [currentUser])

  const iterateFriendRequests = () => {
    if (!notifications?.length) return null

    return notifications.map(n => {
      const {requester_id} = n

      return (
        <div id={"request_" + requester_id} key={requester_id} className="notification__container">
          <span className="notification__name">{n.requester_name} </span>
          sent you a friend request!

          <div className="notification__btn--container">
            <button onClick={() => acceptFriendRequest(requester_id)} className="notification__accept">Accept</button>
            <button onClick={() => ignoreFriendRequest(requester_id)} className="notification__ignore">Ignore</button>
          </div>
        </div>
      )
    })
  }

  const acceptFriendRequest = requesterId => {
    const params = {requester_id: requesterId, firebase_uid: currentUser.uid}
    axios.post(acceptFriendRequestRoute, params)
      .then(r => {
        r.data.success && toggleFriendRequestAccepted(true)
        const notification = document.getElementById("request_" + requesterId)
        notification.classList.add("notification__exit")
      })
  }

  const ignoreFriendRequest = requesterId => {
    const params = {requester_id: requesterId, firebase_uid: currentUser.uid}
    axios.post(ignoreFriendRequestRoute, params)
      .then(r => {
        r.data.success && toggleFriendRequestIgnored(true)
        const notification = document.getElementById("request_" + requesterId)
        notification.classList.add("notification__exit")
      })
  }

  if (!currentUser) return null

  return (
    <div className="notifications__container">
      {iterateFriendRequests()}
      {showFriendRequestAccepted && alert('Friend request accepted!')}
      {showFriendRequestIgnored && alert('Friend request ignored.')}
    </div>
  )
}

export default Notifications
