import React, {useState, useContext} from 'react'
import axios from 'axios'
import {userSearchRoute, sendFriendRequestRoute} from '../utils/routes'
import {Modal, Button} from 'react-bootstrap'
import { AuthContext } from '../auth'

const FriendFinder = props => {
  const {currentUser} = useContext(AuthContext)
  const [searchResults, setSearchResults] = useState([])
  const [showErrorMessage, toggleErrorMessage] = useState(false)
  const [showModal, toggleModal] = useState(true)

  const handleClose = () => {
    toggleModal(false)
  }

  const searchUsers = e => {
    e.preventDefault()

    let email = e.target.value
    axios.post(userSearchRoute, {email})
      .then(r => {
        if (r.data.success) {
          setSearchResults(r.data.results)
        }
      })
      .catch(e => {
        toggleErrorMessage(true)
        console.error('An error occurred while searching for users: ', e)
      })
  }

  const sendFriendRequest = user => {
    const shouldSend = confirm(`Send a friend request to ${user.name}?`)

    if (shouldSend) {
      const params = {firebase_uid: currentUser.uid, receiver_id: user.id}

      axios.post(sendFriendRequestRoute, params)
        .then(r => {
          if (r.data.success) {
            alert(`Your friend request has been sent to ${user.name}!`)
          } else {
            alert('Sorry, something went wrong. Please try again.')
          }
        })
      .catch(e => {
        console.log('Error occurred: ', e)
        alert('Sorry, something went wrong. Please try again.')
      })
    }
  }

  const renderSearchResults = () => {
    if (searchResults.length === 0) return null

    return searchResults.map(user => {
      return (
        <div className="friends__search-result--container" key={user.id} onClick={() => sendFriendRequest(user)}>
          <span className="friends__search-result--name">
            {user.name}
          </span>

          <span className="friends__search-result--email">
            {user.email}
          </span>
        </div>
      )
    })
  }

  return (
    <Modal show={showModal} onHide={handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Find Friends</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <section className="modal__body">
          {showErrorMessage && (
            <div className="friends__error">
              Sorry, something went wrong. Try searching again.
            </div>
          )}

          <input
            type="text"
            className="friends__finder--input"
            placeholder="Search by email..."
            onChange={e => searchUsers(e)}
          />

          {renderSearchResults()}

        </section>
      </Modal.Body>

      <Modal.Footer>
        <div className="modal__footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default FriendFinder
