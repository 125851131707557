import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { getVideoRoute } from '../utils/routes'
import locationIcon from '../images/miscellaneous_icons/search-icon.png'
import Nav from '../nav'
import Footer from '../footer'

const SelectLocation = ({match, history}) => {
  const { register, handleSubmit } = useForm()
  const { league, sport } = match.params
  const [videoLink, setVideoLink] = useState('')

  useEffect(() => {
    const getVideoLink = () => {
      axios.get(getVideoRoute, {params: {league, sport} })
        .then(r => setVideoLink(
          `https://storage.googleapis.com/places2play_public/videos/background-videos/${r.data}`
        ))
    }

    getVideoLink()
  }, [])

  const onSubmit = (data, e) => {
    e.preventDefault();
    history.push(`/${league}/${sport}/${data.location}/search_results`)
  }

  const useCurrentLocation = e => {
    e.preventDefault()
    navigator.geolocation.getCurrentPosition(position => {
      const lat = position.coords.latitude
      const long = position.coords.longitude
      history.push(`/${league}/${sport}/${lat},${long}/search_results`)
    })
  }

  const renderBgVideo = () => {
    if (!videoLink) return null

    return (
      <video autoPlay muted loop id="sport-video">
        <source src={videoLink} type="video/mp4" />
      </video>
    )
  }

  return (
    <>
      <div className="select-location__container">
        <div className="select-location__header--area">
          <Nav style={'light'}/>
        </div>

        <div className="select-location__search">
          <form onSubmit={handleSubmit(onSubmit)} className="select-location__form">
            <input
              type="text"
              className="select-location__input"
              placeholder="Enter a location..."
              name="location"
              ref={register}
            />

            <button className="btn__no-styles">
              <img src={locationIcon} className="select-location__icon" onClick={handleSubmit} />
            </button>
          </form>

          <button
            className="select-location__btn u-margin-left-small"
            onClick={useCurrentLocation}>
            Use My Location
          </button>

        </div>

        {renderBgVideo()}
      </div>
      <Footer />
    </>
  )
}

export default SelectLocation
