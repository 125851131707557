import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import AppRouter from '../app_router'
import {AuthProvider} from '../auth'

const history = createBrowserHistory({forceRefresh:true})

const Application = (props) => {
  return (
    <AuthProvider>
      <Router history={history}>
        <AppRouter />
      </Router>
    </AuthProvider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Application />,
    document.getElementById('application'))
})
