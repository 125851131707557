import React, { useState, useEffect } from 'react'
import {capitalize} from '../utils'
import {sportsRoute} from '../utils/routes'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Footer from '../footer'
import iconsArr from './icons'
import pickSportIcon from '../images/sports_icons/pick-a-sport.png'
import Nav from '../nav'

const SelectSport = ({league}) => {
  const [sports, setSports] = useState([])

  useEffect(() => {
    const getSports = () => {
      axios.get(sportsRoute, {params: {league}}).then(r => setSports(r.data))
    }
    getSports()
    changeHeaderText()
  }, [])

  useEffect(() => {
    moveOtherToLast()
  }, [sports])

  const changeHeaderText = () => {
    if (document.querySelector(".header").innerText.includes("Adults")) {
      document.querySelector(".header").innerText = "Adult Sports"
    }
  }

  const moveOtherToLast = () => {
    if (!sports.length) return null
    const sportLinks = document.getElementsByClassName("sports__item--link")
    const otherLink = Array.from(sportLinks).find(s => s.innerHTML.includes("Other"))
    const sportsContainer = document.querySelector(".sports__container")
    if (sportsContainer && otherLink) sportsContainer.appendChild(otherLink.parentNode)
  }

  const renderSports = () => {
    if (!sports.length) return null

    sports.sort((a, b) => a.name.localeCompare(b.name))

    return sports.map(sport => {
      let iconSrc = iconsArr.find(iconPath => iconPath.includes(sport.icon))

      if (sport.name === "Other") {
        return (
          <div className="sports__item" key={sport.id}>
            <Link
              key={sport.id}
              to={`/${league}/other/sports`}
              className="sports__item--link"
            >
              <img
                src={iconSrc}
                className="sport__icon"
              />
              <span className="sport__name">{sport.name}</span>
            </Link>
          </div>
        )
      } else {
        return (
          <div className="sports__item" key={sport.id}>
            <Link
              key={sport.id}
              to={`/${league}/${sport.name}/location`}
              className="sports__item--link"
            >
              <img
                src={iconSrc}
                className="sport__icon"
              />

              <span className="sport__name">{sport.name}</span>
            </Link>
          </div>
        )
      }
    })
  }

  return (
    <div className="select_sport__container">
      <div className={`${league}_sports__header--area`}>
        <Nav style="light"/>
        <h1 className="header">{capitalize(league)} Sports</h1>
      </div>

      <div className="select_sport__header--container">
        <img src={pickSportIcon} className="select_sport__icon" />

        <h2 className="select_sport__header--secondary">
          Pick a sport
        </h2>
      </div>

      <div className="sports__container">
        { renderSports() }
      </div>

      <Footer />
    </div>
  )
}

export default SelectSport
