import React, { useState, useEffect, useContext } from 'react'
import {GOOGLE_CLOUD_STORAGE_PUBLIC_PATH} from '../utils'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Map from '../map/index'
import SearchResultHit from './search_result_hit'
import UserTips from './user_tips'
import Spinner from '../spinner'
import { AuthContext } from '../auth'
import Nav from '../nav'
import Footer from '../footer'
import {
  mapKeyRoute,
  interactionsRoute,
  sportsVenueRoutes,
  currentUserRoute
} from '../utils/routes'

const SearchResults = ({ match, history }) => {
  const { currentUser } = useContext(AuthContext)
  const [currentUserId, setCurrentUserId] = useState()
  const [venues, setVenues] = useState([])
  const [userLocation, setUserLocation] = useState()
  const [hitCount, setHitCount] = useState()
  const [selectedVenue, setSelectedVenue] = useState()
  const [imagePath, setImagePath] = useState(
    'https://storage.googleapis.com/places2play_public/'
  )
  const [showSpinner, toggleSpinner] = useState(true)
  const { league, sport, location, page } = match.params

  const nextPage = page ? Number(page) + 1 : 1
  const prevPage = page > 0 ? Number(page) - 1 : ''

  useEffect(() => {
    getUserLocation()
  }, [])

  useEffect(() => {
    currentUser && getUserRailsID(currentUser.uid)
    return () => {
      null
    }
  }, [currentUser])

  useEffect(() => {
    const params = { league, sport, location, page }
    getVenues(params)
    updateSponsoredTextOnPageChange()
  }, [page])

  useEffect(() => {
    updateSponsoredTextOnVenueChange()
    return () => {}
  }, [venues])

  const getUserRailsID = firebase_uid => {
    const params = {
      firebase_uid
    }
    axios.get(currentUserRoute, { params }).then(r => {
      setCurrentUserId(r.data.id)
    })
  }

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(pos => {
      const posLat = pos.coords.latitude
      const posLong = pos.coords.longitude
      const posCoords = {latitude: posLat, longitude: posLong}
      setUserLocation(posCoords)
    }, () => alert('Could not find your location.'))
  }

  const getVenues = params => {
    axios.get(sportsVenueRoutes, { params }).then(r => {
      toggleSpinner(false)
      setVenues(r.data.sports_venues)
      setHitCount(r.data.hit_count)
    })
  }

  const updateSponsoredTextOnPageChange = () => {
    let existingText = document.getElementsByClassName('sponsored-text')
    const shouldRemoveText = page > 0 && existingText.length > 0
    if (shouldRemoveText) removeSponsoredText()
  }

  const updateSponsoredTextOnVenueChange = () => {
    let hits = document.getElementsByClassName('search-result__card')
    let existingText = document.getElementsByClassName('sponsored-text')

    const shouldAddText =
      hits.length > 2 &&
      existingText.length === 0 &&
      (page == 0 || page == undefined)

    if (shouldAddText) addSponsoredText()
  }

  const addSponsoredText = () => {
    let sponsoredResultNode = document.createElement('div')
    let sponsoredText = document.createTextNode('Sponsored Results')
    let hitsContainer = document.getElementsByClassName(
      'search-results__hits'
    )[0]
    let hits = document.getElementsByClassName('search-result__card')

    sponsoredResultNode.appendChild(sponsoredText)
    sponsoredResultNode.classList.add('sponsored-text')
    hitsContainer.insertBefore(sponsoredResultNode, hits[0])

    const allResultsNode = document.createElement('div')
    const textNode = document.createTextNode('All Results')

    allResultsNode.appendChild(textNode)
    allResultsNode.classList.add('sponsored-text')
    hitsContainer.insertBefore(allResultsNode, hits[2])
  }

  const removeSponsoredText = () => {
    let existingNodes = Array.from(
      document.getElementsByClassName('sponsored-text')
    )
    let numberOfNodes = document.getElementsByClassName('sponsored-text').length

    if (numberOfNodes > 0 && page > 0) {
      existingNodes.forEach(n => n.remove())
    }
  }

  const renderSpinner = show => toggleSpinner(show)

  const toggleInteraction = (venueId, type) => {
    const params = {
      enue_id: venueId,
      user_id: currentUserId,
      type: type
    }
    axios.post(interactionsRoute, { ...params }).then(r => {
      const postStatus = r.data.success
      if (postStatus) {
        getVenues('all')
      } else {
        return error
      }
    })
  }

  const handleMarkerClick = venue => setSelectedVenue(venue)

  const handleNext = () =>
    history.push(`/${league}/${sport}/${location}/search_results/${nextPage}`)

  const handlePrev = () =>
    history.push(`/${league}/${sport}/${location}/search_results/${prevPage}`)

  const renderPaginationBtns = () => {
    if (hitCount) {
      var maxPage = (Math.ceil(hitCount / 10)) - 1
    } else {
      var maxPage = 10
    }

    if (showSpinner || !venues.length || hitCount && hitCount <= 10) return null
    if (page < 1 || page === undefined) {
      return (
        <div className='search-results__next' onClick={handleNext}>
          Next Page &rarr;
        </div>
      )
    } else if (Number(page) + 1 > maxPage) {
      return (
        <div className='search-results__next u-margin-right-xs' onClick={handlePrev}>
          &larr; Previous Page
        </div>
      )
    } else {
      return (
        <div className="search-results__nav--container">
          <div className='search-results__next u-margin-right-xs' onClick={handlePrev}>
            &larr; Previous Page
          </div>

          <div className='search-results__next' onClick={handleNext}>
            Next Page &rarr;
          </div>
        </div>
      )
    }
  }

  const renderHitsOrSpinner = () => {
    if (showSpinner) {
      return (
        <div className='spinner__container'>
          <Spinner />
        </div>
      )
    }

    return (
      <>
        {venues.map(venue => (
          <SearchResultHit
            venue={venue}
            key={venue.id}
            setSelectedVenue={setSelectedVenue}
            userLocation={userLocation}
          />
        ))}
      </>
    )
  }

  const renderNoResultsMessage = () => {
    if (showSpinner) return null
    if (!venues.length) return <div className="search-results__no--results">No places found. Try searching again.</div>
  }

  const renderResultsMessage = () => {
    if (!hitCount) return null
    return <span className="search-results__hit--count">{`${hitCount} results found`}</span>
  }

  const renderHeader = () => {
    if (venues.length) return <h1 className="header">Results</h1>
  }

  const renderBannerAd = () => {
    return (
      <a href="https://www.dickssportinggoods.com/" target="_blank" className="search-results__banner--container">
        <img
          className="search-results__banner"
          src={`${GOOGLE_CLOUD_STORAGE_PUBLIC_PATH}/images/banner-ad.jpg`}
        />
      </a>
    )
  }

  const hideMap = hitCount === 0 ? 'hide-map' : ''

  return (
    <>
      <div className="select-location__container">
        <div className="search-results__header--area">
          <Nav />
          {renderHeader()}
        </div>

        <div className="u-margin-top-medium"></div>
        <UserTips sport={sport} match={match} />

        <div className='search-results-outer'>
          {renderNoResultsMessage()}

          <div className='search-results__hits'>
            {renderHitsOrSpinner()}
          </div>

          <div className='search-results__nav--container'>
            {renderResultsMessage()}
            {renderPaginationBtns()}
          </div>

          <div className={`search-results-map ${hideMap}`}>
            <Map
              remove={true}
              venues={venues}
              currentUserId={currentUserId}
              selectedVenue={selectedVenue}
              toggleInteraction={toggleInteraction}
              handleMarkerClick={handleMarkerClick}
              imagePath={imagePath}
              mapType={'searchResults'}
            />
          </div>

        </div>

        {renderBannerAd()}
      </div>

      <Footer />
    </>
  )
}

export default SearchResults
