import React, {useState} from 'react'
import SportsVenueModalData from './modal_data'
import {Modal, Button} from 'react-bootstrap'

const SportsVenueModal = ({sportsVenue}) => {
  const [show, toggleShow] = useState(true)
  const {name} = sportsVenue

  const handleClose = () => {
    toggleShow(false)
  }

  return (
    <Modal show={show} onHide={handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <section className="modal__body">
          <img src="https://storage.googleapis.com/places2play_public/venue/jd-reed-park.jpg" className="modal__body--image" />

        <SportsVenueModalData sportsVenue={sportsVenue} />

        </section>
      </Modal.Body>

      <Modal.Footer>
        <div className="modal__footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SportsVenueModal
