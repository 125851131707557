import React, { useState, useEffect, useContext } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import axios from 'axios'
import { AuthContext } from '../../../auth'
import { currentUserRoute, favoriteSportsVenues, interactionsVenuePlayedRoute } from '../../../utils/routes'

const Played = props => {
  const { selectedVenue, postAlert, currentUserId } = props
  const {currentUser} = useContext(AuthContext)

  useEffect(() => {
    setIconColor(selectedVenue.is_played)
  }, [selectedVenue])

  const setIconColor = venue_played => {
    const setColorElement = document.getElementById('daasly-popup-played')
    const classList = setColorElement.classList
    setColorElement && venue_played === true
      ? setIconColorTrue(classList)
      : setIconColorFalse(classList)
  }

  const setIconColorTrue = classList => {
    classList.add('daasly-icon-green')
    classList.remove('daasly-icon-default')
  }

  const setIconColorFalse = classList => {
    classList.add('daasly-icon-default')
    classList.remove('daasly-icon-green')
  }

  const setMarkerColorTrue = venueId => {
    var markerContainer = document.getElementById(`marker-${venueId}`)
    markerContainer.classList.remove('daasly-marker-red')
    markerContainer.classList.add('daasly-marker-green', 'u-z-index')
  }

  const setMarkerColorFalse = venueId => {
    var markerContainer = document.getElementById(`marker-${venueId}`)
    markerContainer.classList.remove('daasly-marker-green')
    markerContainer.classList.add('daasly-marker-red', 'u-z-index')
  }

  const togglePlayed = venue_id => {
    if (currentUser) {
      const params = {firebase_uid: currentUser.uid, venue_id}
      axios.post(interactionsVenuePlayedRoute, params).then(r => {
        if (r.data.success) {
          const popupClassList = document.getElementById('daasly-popup-played').classList

          switch (r.data.venue_played) {
            case true:
              alert('Venue was saved to your Places Played!')
              setIconColorTrue(popupClassList)
              setMarkerColorTrue(venue_id)
              break
            case false:
              alert('Venue was removed from your Places Played.')
              setIconColorFalse(popupClassList)
              setMarkerColorFalse(venue_id)
              break
          }
        } else {
          alert('Sorry, an error occurred.')
        }
      })
    } else {
      alert('Please sign in to save this location to your Places Played.')
    }
  }

  return (
    <div>
      {selectedVenue ? (
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Played</Tooltip>}>
          <button
            className='daasly-popup-played'
            onClick={() => togglePlayed(selectedVenue.id) }
          >
            <i
              id='daasly-popup-played'
              className='fas fa-check daasly-icon-default'
            />
          </button>
        </OverlayTrigger>
      ) : null}
    </div>
  )
}

export default Played
